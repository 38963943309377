import {
  Typography,
  Divider,
  Space,
  Button,
  Form,
  Input,
  Select,
  notification,
  Tooltip,
  Table,
  Modal,
  Row,
  Col,
  Spin,
} from 'antd';
const { Option } = Select;
const { Title } = Typography;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { DeleteOutlined } from '@ant-design/icons';
import _ from 'lodash';
import Schema from 'async-validator';
import urlConstant from '../../../constants/urlConstant';
import commonService from '../../../services/commonService';
import { getScentCardDetailById, getTestNameList } from '../../../store/action/actions';
import messageConstant from '../../../constants/messageConstant';
let testKitId = '';
let ScentCardId = '';
let userRole;
import dataConstant from '../../../constants/dataConstant';

const EditOptionDetails = (props) => {
  Schema.warning = function () {};
  const child = React.createRef();
  testKitId = props.match.params.Id;
  ScentCardId = props.match.params.ScentCardId;
  const { state } = props.location;
  const { scentCardDetails, res } = useSelector((state) => state.getData);
  const dispatch = useDispatch();
  const history = useHistory();
  const [scentCardApiData, setScentCardApiData] = useState();
  const [currentOptionIndex, setCurrentOptionIndex] = useState(0);
  const [loader, setLoader] = useState(false);
  const [dropDownOption, setDropDownOption] = useState();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [scentCardId, setScentCardId] = useState(props.match.params.Id);
  const [activeKey, setActiveKey] = useState('1');
  const [modalVisible, setModalVisible] = useState(false);
  const [rowValue, setRowValue] = useState(0);
  const [switchEdit, setSwitchEdit] = useState(false);

  const [dataSource, setDataSource] = useState({
    id: 0,
    testKitId: null,
    name: '',
    scentCardOptionResultId: null,
    sortOrder: null,
    ScentCardOptions: [],
  });

  useEffect(() => {
    let LoginUserDetails = localStorage.getItem('user');
    userRole = JSON.parse(LoginUserDetails).roleName;
    if (userRole != dataConstant.Role.Admin) {
      history.push('/admin/dashboard');
    }
    dispatch(getScentCardDetailById(ScentCardId));
  }, []);

  useEffect(() => {
    setScentCardApiData(scentCardDetails.data);
  }, [scentCardDetails]);

  useEffect(() => {
    dropDownForScentCardOption();
  }, []);

  const startDropDown = () => {
    form.setFieldsValue({
      Id: scentCardDetails.data.id,
      Name: scentCardDetails.data.name,
      ScentCardOptionResultId: scentCardDetails.data.scentCardOptionResultId,
      SortOrder: scentCardDetails.data.sortOrder,
      TestKitName: scentCardDetails.data.testKitName,
    });
    form2.setFieldsValue({
      Id: scentCardDetails.data.scentCardOptions.scentCardId,
      ScentCardOptionDetailId: scentCardDetails.data.scentCardOptions.scentCardOptionDetailId,
      SortOrder: scentCardDetails.data.scentCardOptions.sortOrder,
      ScentCardOptionId: scentCardDetails.data.scentCardOptions.scentCardOptionId,
      ScentCardOptionStoredPath: scentCardDetails.data.scentCardOptions.scentCardOptionStoredPath,
      ScentCardOptionName: scentCardDetails.data.scentCardOptions.scentCardOptionName,
    });
  };

  useEffect(() => {
    if (scentCardApiData && dropDownOption) {
      if (ScentCardId > 0) {
        startDropDown();
      }
    }
  }, [scentCardApiData, dropDownOption]);

  const handleAdd = () => {
    setModalVisible(true);
    setSwitchEdit(true);
    form2.resetFields();
  };

  const dropDownForScentCardOption = () => {
    commonService.httpGet(urlConstant.ScentCardOptionDetail.DropdownForScentCardOption, true).then((response) => {
      if (response) {
        setDropDownOption(response.data);
      }
    });
  };

  const onFinish = (values) => {
    setLoader(true);
    let newObj = {
      ScentCardOptions: [],
    };

    let org = scentCardApiData;

    if (ScentCardId === '0') {
      newObj.name = values.Name;
      newObj.sortOrder = values.SortOrder;
      newObj.scentCardOptionResultId = values.ScentCardOptionResultId;
      newObj.testKitId = testKitId;
      _.forEach(dataSource.ScentCardOptions, function (value, key) {
        const objoption = {
          scentCardOptionDetailId: value.scentCardOptionDetailId,
          sortOrder: value.sortOrder,
          ScentCardOptionId: 0,
          ScentCardId: 0,
        };
        newObj.ScentCardOptions.push(objoption);
      });
      if (!newObj.ScentCardOptions.find((item) => item.scentCardOptionDetailId === newObj.scentCardOptionResultId)) {
        setLoader(false);
        notification.error({
          message: 'Scent Card',
          description: dataConstant.manageScentCard.correctAnswerWarning,
        });
        return;
      }
      var sortOrderArray = newObj.ScentCardOptions.map(function (item) {
        return item.sortOrder;
      });
      var isSortOrderDuplicate = sortOrderArray.some(function (item, idx) {
        return sortOrderArray.indexOf(item) != idx;
      });
      if (isSortOrderDuplicate == true) {
        setLoader(false);
        notification.warning({
          message: 'Scent Card',
          description: dataConstant.manageScentCard.sortOrderUnique,
        });
        return;
      }
      if (newObj.ScentCardOptions.length != 5) {
        setLoader(false);
        notification.warning({
          message: 'Scent Card',
          description: dataConstant.manageScentCard.scentCardMinimumWarning,
        });
        return;
      }
    } else {
      if (scentCardApiData && scentCardApiData.scentCardOptions !== null) {
        setLoader(false);
        org.name = values.Name;
        org.sortOrder = values.SortOrder;
        org.scentCardOptionResultId = values.ScentCardOptionResultId;
        setScentCardApiData(org);
      }
      if (!org.scentCardOptions.find((item) => item.scentCardOptionDetailId === org.scentCardOptionResultId)) {
        setLoader(false);
        notification.error({
          message: 'Scent Card',
          description: dataConstant.manageScentCard.correctAnswerWarning,
        });
        return;
      }

      //   var sortOrderArray = org.scentCardOptions.map(function (item) {
      //     return item.sortOrder;
      //   });
      //   var isSortOrderDuplicate = sortOrderArray.some(function (item, idx) {
      //     return sortOrderArray.indexOf(item) != idx;
      //   });
      if (isSortOrderDuplicate == true) {
        setLoader(false);
        notification.warning({
          message: 'Scent Card',
          description: dataConstant.manageScentCard.sortOrderUnique,
        });
        return;
      }
      if (org.scentCardOptions.length != 5) {
        setLoader(false);
        notification.warning({
          message: 'Scent Card',
          description: dataConstant.manageScentCard.scentCardMinimumWarning,
        });
        return;
      }
    }

    if (ScentCardId === '0') {
      commonService
        .httpPost(urlConstant.ScentCard.CreateSentCardDetails, newObj, true)
        .then((response) => {
          if (response.status === dataConstant.APIStatus.Success) {
            setLoader(false);
            notification.success({
              message: `Scent Card`,
              description: response.message,
            });
            history.replace({
              pathname: `/admin/manageTestkit/${testKitId}`,
              state: '2',
            });
            setScentCardId(response.data.scentCardId);
            setActiveKey('2');
          } else if (
            response.status === dataConstant.APIStatus.Failure ||
            response.status == dataConstant.APIStatus.Warning
          ) {
            setLoader(false);
            notification.warning({
              message: dataConstant.somethingWentWrong,
              description: response.message,
            });
          }
        })
        .catch((error) => {
          setLoader(false);
          notification.error({
            message: dataConstant.somethingWentWrong,
            description: error.message,
          });
        });
    } else {
      commonService
        .httpPost(urlConstant.ScentCard.UpdateSentCardDetails, org, true)
        .then((response) => {
          if (response.status === dataConstant.APIStatus.Success) {
            setLoader(false);
            notification.success({
              message: `Scent Card`,
              description: response.message,
            });
            history.replace({
              pathname: `/admin/manageTestkit/${testKitId}`,
              state: '2',
            });
            setScentCardId(response.data.scentCardId);
            setActiveKey('2');
          } else if (response.status === dataConstant.APIStatus.Failure) {
            setLoader(false);
            notification.warning({
              message: `Scent Card`,
              description: response.message,
            });
          } else if (response.status === dataConstant.APIStatus.Warning) {
            setLoader(false);
            notification.warning({
              message: `Scent Card`,
              description: response.message,
            });
          }
        })
        .catch((error) => {
          setLoader(false);
          notification.error({
            message: dataConstant.somethingWentWrong,
            description: error.message,
          });
        });
    }
  };

  const columns = [
    {
      title: 'Option Name',
      dataIndex: 'scentCardOptionDetailId',
      key: 'scentCardOptionDetailId',
      sorter: true,
      width: 200,
      dataType: 'StringContains',
      render: (text) => (
        <a>
          {dropDownOption &&
            // eslint-disable-next-line consistent-return
            dropDownOption.map((item, index) => {
              if (parseInt(text) === item.id) {
                let ConvertedText = item.title;
                return <span key={item.id}>{ConvertedText}</span>;
              }
            })}
        </a>
      ),
    },
    {
      title: 'Option Order',
      dataIndex: 'sortOrder',
      key: 'sortOrder',
      sorter: true,
      width: 200,
      dataType: 'StringContains',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Delete',
      dataIndex: 'Delete',
      align: 'center',
      width: 100,
      render: (_, record) =>
        ScentCardId === '0' ? (
          dataSource.ScentCardOptions.length > 0 ? (
            <>
              <Tooltip placement="bottom" title="Delete">
                <Button
                  icon={<DeleteOutlined />}
                  size="small"
                  onClick={() => handleDelete(record.scentCardOptionDetailId)}
                />
              </Tooltip>
            </>
          ) : null
        ) : scentCardApiData.scentCardOptions.length > 0 ? (
          <>
            <Tooltip placement="bottom" title="Delete">
              <Button
                icon={<DeleteOutlined />}
                size="small"
                onClick={() =>
                  record.scentCardOptionId === 0
                    ? handleDelete(record.scentCardOptionDetailId)
                    : handleDelete(record.scentCardOptionId)
                }
              />
            </Tooltip>
          </>
        ) : null,
    },
  ];

  const handleDelete = (key) => {
    Modal.confirm({
      title: messageConstant.DeleteConfirmation,
      content: messageConstant.DeleteConfirm,
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        deleteRecore(key);
      },
    });
  };

  const deleteRecore = (key) => {
    if (scentCardApiData && scentCardApiData.scentCardOptions !== null) {
      const newData =
        scentCardApiData &&
        scentCardApiData.scentCardOptions.filter((item) =>
          item.scentCardOptionId === 0 ? item.scentCardOptionDetailId !== key : item.scentCardOptionId !== key
        );
      setScentCardApiData({ ...scentCardApiData, scentCardOptions: newData });
    } else {
      const newData = dataSource && dataSource.ScentCardOptions.filter((item) => item.scentCardOptionDetailId !== key);
      setDataSource({ ...dataSource, ScentCardOptions: newData });
    }
  };

  const handleOk = (value) => {
    setModalVisible(false);
    let org = scentCardApiData;
    const newData =
      org.scentCardOptions &&
      org.scentCardOptions.map((item) => {
        if (item.scentCardOptionId === rowValue) {
          item.scentCardOptionDetailId = value.scentCardOptionDetailId;
          item.sortOrder = value.sortOrder;
        }
        return item;
      });
    setScentCardApiData(newData);
  };

  const onFormFinish = (values) => {
    setModalVisible(false);
    if (ScentCardId === '0') {
      setDataSource({
        ...dataSource,
        ScentCardOptions: [
          ...dataSource.ScentCardOptions,
          {
            scentCardId: parseInt(ScentCardId),
            scentCardOptionDetailId: values.scentCardOptionDetailId,
            sortOrder: values.sortOrder,
            scentCardOptionId: 0,
          },
        ],
      });
      setSwitchEdit(false);
    } else {
      setScentCardApiData({
        ...scentCardApiData,
        scentCardOptions: [
          ...scentCardApiData.scentCardOptions,
          {
            scentCardId: parseInt(ScentCardId),
            scentCardOptionDetailId: values.scentCardOptionDetailId,
            sortOrder: values.sortOrder,
            scentCardOptionId: 0,
          },
        ],
      });
      setSwitchEdit(false);
    }
    setModalVisible(false);
  };

  const onFormFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleClose = () => {
    setModalVisible(false);
    setSwitchEdit(false);
  };

  const redirectToBack = () => {
    history.replace({
      pathname: `/admin/manageTestkit/${testKitId}`,
      state: '2',
    });
  };

  if (!res || loader) {
    return (
      <Space size="middle" className="cmLoader">
        <Spin size="small"></Spin>
      </Space>
    );
  }

  return (
    <>
      <div className="page-header">
        <strong>
          {testKitId && ScentCardId > 0
            ? `Test Kit: ${scentCardApiData && scentCardApiData.testKitName} / Edit Scent Card: ${
                scentCardApiData && scentCardApiData.name
              }`
            : `Test Kit: ${state && state.CurrentTestKitName} / Create Scent Card`}
        </strong>
      </div>
      <Form layout="inline" name="basic" form={form} onFinish={onFinish} autoComplete="off" className="py-3">
        <Form.Item
          label="Scent Card Name"
          name="Name"
          id="Name"
          rules={[
            {
              required: true,
              message: dataConstant.manageScentCard.scentCardFormName,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Sort Order"
          name="SortOrder"
          id="SortOrder"
          rules={[
            {
              required: true,
              message: dataConstant.manageScentCard.scentCardFormSortOrder,
            },
          ]}
        >
          <Select
            initialvalues={scentCardApiData && scentCardApiData.sortOrder}
            style={{
              width: 120,
            }}
          >
            <Option value="1">1</Option>
            <Option value="2">2</Option>
            <Option value="3">3</Option>
            <Option value="4">4</Option>
            <Option value="5">5</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Correct Answer"
          name="ScentCardOptionResultId"
          id="ScentCardOptionResultId"
          rules={[
            {
              required: true,
              message: dataConstant.manageScentCard.scentCardFormCorrectAnswer,
            },
          ]}
        >
          <Select
            showSearch
            initialvalues={scentCardApiData && scentCardApiData.scentCardOptionResultId}
            style={{
              width: 120,
            }}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          >
            {dropDownOption &&
              dropDownOption.map((item) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.title}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
      </Form>
      <Row>
        <Col span={24}>
          <Divider className="mb-3 mt-0" />
        </Col>
      </Row>
      <Row justify="end">
        <Col span={20}>
          <Title level={4}>Scent Card Option List</Title>
        </Col>
        <Col span={4}>
          <Row justify="end">
            <Button type="primary" onClick={() => handleAdd()}>
              Add Option
            </Button>
          </Row>
        </Col>
      </Row>

      <Table
        rowKey="sortOrder"
        columns={columns}
        dataSource={
          ScentCardId === '0'
            ? dataSource && dataSource.ScentCardOptions
            : scentCardApiData && scentCardApiData.scentCardOptions
        }
      />

      <Form name="basic" form={form} onFinish={onFinish}>
        <Row gutter={16} justify="end">
          <div justify="end">
            <Button type="default" onClick={() => redirectToBack()} className="mr-3 mt-3">
              Cancel
            </Button>
            <Button style={{ align: 'right' }} type="primary" htmlType="submit">
              {ScentCardId != '0' ? 'Save' : 'Create'}
            </Button>
          </div>
        </Row>
      </Form>
      <br />
      <Modal
        title="Add Scent Card Option"
        centered
        visible={modalVisible}
        onOk={() => handleOk()}
        onCancel={() => handleClose()}
        footer={null}
        getContainer={false}
        forceRender={true}
        className="scent-card-option-popup"
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          name="form2"
          form={form2}
          onFinish={onFormFinish}
          onFinishFailed={onFormFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Option Name"
            name="scentCardOptionDetailId"
            id="ScentCardOptionDetailId"
            validateTrigger="onChange"
            rules={[
              {
                required: true,
                message: dataConstant.manageScentCard.scentCardModalOptionName,
              },

              {
                message: 'Option Already set!',
                validator: (_, value) => {
                  let scentCardOptions = [];

                  if (ScentCardId == '0') {
                    scentCardOptions = (dataSource && dataSource.ScentCardOptions) || [];
                  } else {
                    scentCardOptions = (scentCardApiData && scentCardApiData.scentCardOptions) || [];
                  }
                  scentCardOptions.map((item) => {
                    if (item.scentCardOptionDetailId === value) {
                      throw new Error('Option Already set!');
                    }
                    return Promise.resolve();
                  });
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Select
              showSearch
              initialvalues={'Select Option'}
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            >
              {dropDownOption &&
                dropDownOption.map((item, index) => (
                  <Option value={item.id} key={item.id}>
                    {item.title}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Sort Order"
            name="sortOrder"
            id="SortOrder"
            validateTrigger="onChange"
            rules={[
              {
                required: true,
                message: dataConstant.manageScentCard.scentCardModalSortOrder,
              },
              {
                message: dataConstant.manageScentCard.scentCardModalSortOrderAlreadyExists,
                validator: (_, value) => {
                  let scentCardOptions = [];

                  if (ScentCardId == '0') {
                    scentCardOptions = (dataSource && dataSource.ScentCardOptions) || [];
                  } else {
                    scentCardOptions = (scentCardApiData && scentCardApiData.scentCardOptions) || [];
                  }
                  scentCardOptions.map((item) => {
                    if (item.sortOrder === value) {
                      throw new Error(dataConstant.manageScentCard.scentCardModalSortOrderAlreadyExists);
                    }
                    return Promise.resolve();
                  });
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Select initialvalues={'Select order'}>
              <Option value={1}>1</Option>
              <Option value={2}>2</Option>
              <Option value={3}>3</Option>
              <Option value={4}>4</Option>
              <Option value={5}>5</Option>
            </Select>
          </Form.Item>
          <Form.Item className="action-footer" justify="end">
            <Button htmlType="button" onClick={handleClose} className="mr-3">
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditOptionDetails;
