import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import { Tooltip, Button, Form, Row, Input, Modal, notification } from 'antd';
import { EditOutlined, DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons';
import TableComponent from '../../../TableComponent';
import dataConstant from '../../../constants/dataConstant';
import commonService from '../../../services/commonService';
import urlConstant from '../../../constants/urlConstant';
import messageConstant from '../../../constants/messageConstant';

function InsuranceProvider() {
  let userRole;
  const child = React.createRef();
  const history = useHistory();
  const [filterform] = Form.useForm();
  const [insuranceProviderData, setInsuranceProviderData] = useState({
    insuranceProviderList: '',
    insuranceProviderTotalcount: '',
  });
  const debounceFilterData = useMemo(() => debounce(applyFilter, 1000), []);
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [tableRequestParam, setTableRequestParam] = useState({
    pageNumber: dataConstant.tableRequest.pageNumber,
    rowsPerPage: JSON.parse(localStorage.getItem('RowsPerPage'))
      ? JSON.parse(localStorage.getItem('RowsPerPage'))
      : dataConstant.tableRequest.rowsPerPage,
    filter: {
      Name: '',
      Email: '',
      Phone: '',
      Website: '',
      Address: '',
      IsActive: '',
    },
    InsuranceProviderId: 0,
    sortColumns: dataConstant.tableRequest.sortColumns,
  });

  // get InsuranceProvider list data
  useEffect(() => {
    let LoginUserDetails = localStorage.getItem('user');
    userRole = JSON.parse(LoginUserDetails).roleName;
    if (userRole != dataConstant.Role.Admin) {
      history.push('/admin/dashboard');
    }
  }, []);

  // fileter data with search text
  useEffect(() => {
    if (searchText !== '') {
      debounceFilterData(searchText);
    } else {
      gridRefresh();
    }
  }, [searchText]);

  // refresh table data with table request param
  const gridRefresh = () => {
    filterform.resetFields();
    setTableRequestParam({
      pageNumber: dataConstant.tableRequest.pageNumber,
      rowsPerPage: JSON.parse(localStorage.getItem('RowsPerPage'))
        ? JSON.parse(localStorage.getItem('RowsPerPage'))
        : dataConstant.tableRequest.rowsPerPage,
      filter: {
        Name: '',
        Email: '',
        Phone: '',
        Website: '',
        Address: '',
      },
      sortColumns: dataConstant.tableRequest.sortColumns,
    });
    tableRequestParam.filter.Name = '';
    tableRequestParam.filter.Email = '';
    tableRequestParam.filter.Phone = '';
    tableRequestParam.filter.Website = '';
    tableRequestParam.filter.Address = '';
    getInsuranceProviderList(tableRequestParam);
  };

  // get Filter data with search text
  function applyFilter(SearchValue) {
    if (SearchValue !== '') {
      const filter = {};
      filter.Name = SearchValue;
      filter.Email = SearchValue;
      filter.Phone = SearchValue;
      filter.Website = SearchValue;
      filter.Address = SearchValue;

      const searchModelData = tableRequestParam;
      searchModelData.filter = filter;
      searchModelData.pageNumber = dataConstant.tableRequest.pageNumber;
      searchModelData.rowsPerPage = JSON.parse(localStorage.getItem('RowsPerPage'))
        ? JSON.parse(localStorage.getItem('RowsPerPage'))
        : dataConstant.tableRequest.rowsPerPage;
      getInsuranceProviderList(tableRequestParam);
    }
  }

  // method for get InsuranceProvider list data from server with table request param
  const getInsuranceProviderList = (requestObject) => {
    setIsLoaderVisible(true);
    const modelData = tableRequestParam;

    if (requestObject) {
      modelData.pageNumber = requestObject.pageNumber;
      modelData.rowsPerPage = requestObject.rowsPerPage;
      modelData.sortColumns = requestObject.sortColumns;
      modelData.filter = requestObject.filter ? requestObject.filter : tableRequestParam.filter;
    }
    commonService
      .httpPost(urlConstant.InsuranceProvider.GetInsuranceProvider, modelData, true, true)
      .then((response) => {
        if (response && response.data) {
          setInsuranceProviderData({
            insuranceProviderList: response.data.list,
            insuranceProviderTotalcount: response.data.totalCount,
          });
          setIsLoaderVisible(false);
        }
      });
  };

  // method redirect to edit InsuranceProvider page with passing InsuranceProvider id as param
  const editInsuranceProviderById = (id) => {
    history.push({
      pathname: `/admin/manageInsuranceProvider/${id}`,
    });
  };

  // method for Add new InsuranceProvider in server from plus icon
  const redirectToAddInsuranceProvider = () => {
    history.push({
      pathname: `/admin/manageInsuranceProvider`,
    });
  };

  // method for refresh table data with table request param
  const Refresh = () => {
    let refreshTableData = tableRequestParam;
    (refreshTableData.pageNumber = dataConstant.tableRequest.pageNumber),
      (refreshTableData.rowsPerPage = JSON.parse(localStorage.getItem('RowsPerPage'))
        ? JSON.parse(localStorage.getItem('RowsPerPage'))
        : dataConstant.tableRequest.rowsPerPage),
      (refreshTableData.filter = {
        Name: '',
        Email: '',
        Phone: '',
        Website: '',
        Address: '',
        IsActive: '',
      }),
      (refreshTableData.InsuranceProviderId = 0),
      (refreshTableData.sortColumns = dataConstant.tableRequest.sortColumns),
      setSearchText('');
    filterform.resetFields();
    getInsuranceProviderList(refreshTableData);
  };

  // Show model for Delete InsuranceProvider
  const confirmDelete = (id) => {
    Modal.confirm({
      title: messageConstant.DeleteConfirmation,
      content: <>{messageConstant.SureMessage}</>,
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        deleteSingleInsuranceProvider(id.toString());
      },
    });
  };

  // method for delete InsuranceProvider in server with InsuranceProvider id
  const deleteSingleInsuranceProvider = (Id) => {
    commonService
      .httpGet(`${urlConstant.InsuranceProvider.DeleteInsuranceProviderById}?Id=${parseInt(Id)}`, true)
      .then((response) => {
        if (response.status === dataConstant.APIStatus.Success) {
          notification.success({
            message: 'InsuranceProvider',
            description: response.message,
          });
          getInsuranceProviderList(tableRequestParam);
        } else if (response.status === dataConstant.APIStatus.Failure) {
          notification.warning({
            message: 'InsuranceProvider',
            description: response.error,
          });
        }
      });
  };

  // set Table data
  const columns = [
    {
      title: 'Action',
      dataIndex: 'action',
      fixed: true,
      align: 'center',
      width: 30,
      render: (text, record) => (
        <span>
          <Tooltip placement="bottom" title="Edit">
            <Button
              icon={<EditOutlined />}
              className="mr-1"
              size="small"
              onClick={() => editInsuranceProviderById(record.insuranceProviderId, record.insuranceProviderName)}
            />
          </Tooltip>
        </span>
      ),
    },
    {
      title: 'Insurance Provider Name',
      dataIndex: 'insuranceProviderName',
      key: 'insuranceProviderName',
      width: 200,
      dataType: 'StringContains',
    },
    {
      title: 'Insurance Provider Website',
      dataIndex: 'insuranceProviderWebsite',
      key: 'insuranceProviderWebsite',
      width: 200,
      dataType: 'StringContains',
    },
    {
      title: 'Insurance Provider Email',
      dataIndex: 'insuranceProviderEmail',
      key: 'insuranceProviderEmail',
      width: 200,
      dataType: 'StringContains',
    },
    {
      title: 'Insurance Provider Phone',
      dataIndex: 'insuranceProviderPhone',
      key: 'insuranceProviderPhone',
      width: 200,
      dataType: 'StringContains',
    },
    {
      title: 'Status',
      dataIndex: 'insuranceProviderIsActive',
      key: 'insuranceProviderIsActive',
      align: 'center',
      width: 160,
      dataType: 'StringContains',
      render: (data) =>
        data === true ? (
          <Button type="primary">Active</Button>
        ) : (
          <Button type="warning" danger>
            Inactive
          </Button>
        ),
    },
    {
      title: 'Delete',
      dataIndex: 'Delete',
      fixed: 'right',
      align: 'center',
      width: 30,
      render: (text, record) => (
        <span>
          <Tooltip placement="bottom" title="Delete">
            <Button onClick={() => confirmDelete(record.insuranceProviderId)} icon={<DeleteOutlined />} size="small" />
          </Tooltip>
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="page-header">
        <h1>Insurance Provider</h1>
        <Form form={filterform}>
          <Row justify="end">
            <Form.Item id="data" label="Search" name="searchtext" className="col-12 col-md-6">
              <div className="d-flex">
                <Input
                  placeholder="Search by Insurance Provider Details"
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <Button
                  icon={<CloseCircleOutlined />}
                  onClick={() => setSearchText('')}
                  htmlType="submit"
                  type="primary"
                />
              </div>
            </Form.Item>
          </Row>
        </Form>
      </div>

      <TableComponent
        ref={child}
        rowKey="insuranceProviderName"
        columns={columns}
        dataSource={insuranceProviderData.insuranceProviderList}
        getGridData={getInsuranceProviderList}
        totalCount={insuranceProviderData.insuranceProviderTotalcount}
        scroll={{ x: 'max-content' }}
        loading={isLoaderVisible}
        refresh={(current) => Refresh(current)}
        isShowAddButton
        addCallbackMethod={redirectToAddInsuranceProvider}
        pageNumber={tableRequestParam.pageNumber}
      />
    </>
  );
}

export default InsuranceProvider;
