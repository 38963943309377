import * as React from 'react';
export default class FrontendLayout extends React.PureComponent<{}, { children?: React.ReactNode }> {
  public render() {
    return (
      <React.Fragment>
        <main>
          <section className="container-fluid front-end-root px-0">
            <div className="row justify-content-center mx-0">
              <div className="col-12 col-md-6 col-lg-5 col-xl-4 px-0">
                <div className="cm-container">{this.props.children}</div>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}
