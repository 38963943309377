import React from 'react';

const Footer = () => {
  return (
    <footer className="page-footer font-small blue admin-footer">
      <div className="footer-copyright text-center">
        &copy; {new Date().getUTCFullYear()} Copyright: MySafePass, All rights reserved
      </div>
    </footer>
  );
};

export default Footer;
