import React, { useRef } from 'react';
import { QrReader } from 'react-qr-reader';

const Camera = (props) => {
  const lastResult = useRef();
  /** after scan complate , call function and get scan data */
  const handleScan = (scanData) => {
    /** To handle Invalid QR code, need to check current and last scan as old data is not removed when back to page */
    if (scanData) {
      if (lastResult.current === scanData.text) {
        return;
      }
      lastResult.current = scanData.text;
      props.onQRCodeScan(scanData);
    }
  };

  return (
    <div>
      <QrReader
        constraints={{ facingMode: 'environment' }}
        delay={500}
        onResult={handleScan}
        style={{ width: '100%', heigth: '100%' }}
        containerStyle={{ width: '100%', heigth: '100%' }}
        videoContainerStyle={{ width: '100%', heigth: '100%' }}
      />
    </div>
  );
};

export default Camera;
