import React, { useState, useEffect } from 'react';
import Camera from '../../Test/Camera';
import dataConstant from '../../constants/dataConstant';
import urlConstant from '../../constants/urlConstant';
import { useHistory } from 'react-router';

const ScanQRCode = () => {
  const history = useHistory();
  const [camera, setCamera] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    /** Check localstorage data (name) on page load */
    if (!localStorage.getItem('safePassData')) {
      history.push('/home');
      return;
    }
    const userData = JSON.parse(localStorage.getItem('safePassData'));
    if (userData && (!userData.FirstName || !userData.LastName)) history.push('/home');
  }, []);

  /** callback function for camera.js: When start QRCode Scan and get text from scanning and pass to scent page */
  const onQRCodeScanStart = (scanData) => {
    /** On Page load, set camera false and  camera is on and get scanData redirect to scent page */
    if (camera) {
      history.push({
        pathname: `/scent/${scanData.text}`,
      });
    }
    setCamera(false);
  };

  return (
    <React.Fragment>
      <header className="d-flex align-items-center justify-content-between">
        <a className="back-btn" href="/home">
          <img src="/resources/images/back_arrow_white.png" alt="arrow" />
        </a>
        <a className="logo" href="/home">
          <img src="/resources/images/logo-white.png" alt="logo" />
        </a>
      </header>
      <div className="scan_code_page">
        <section className="scan_wrapper">
          <div className="extra_links">
            <a href={urlConstant.helpURL.contactus}>
              <img src="/resources/images/ques_mark.png" alt="ques_mark" />
            </a>
          </div>
          <div className="tap_wrapper" onClick={() => setCamera(!camera)}>
            {camera === true ? (
              <div className="scaner img-fluid QRCodeScanner">
                <Camera onQRCodeScan={onQRCodeScanStart} />
              </div>
            ) : (
              <>
                <p>Tap here to scan QR code &quot;B&quot; on the scent card.</p>
                <img className="scaner img-fluid" src="/resources/images/qr_code.png" alt="qrcode" />
              </>
            )}
          </div>
        </section>
        <section className="howto_wrapper">
          <ul className="howto_items">
            <li className="howto_item">
              <img src="/resources/images/rubscent.png" alt="rubscent" />
              <h6>
                Rub <br /> Scent
              </h6>
            </li>
            <li className="howto_item">
              <img src="/resources/images/smell.png" alt="smell" />
              <h6>Smell</h6>
            </li>
            <li className="howto_item">
              <img src="/resources/images/select_scent.png" alt="select_scent" />
              <h6>
                Select
                <br /> Scent
              </h6>
            </li>
          </ul>
        </section>
        <section className="steps_wrapper">
          <div>
            <ul className="steps">
              <li className="step active">
                <span>SCENT</span>
                <span className="count">1</span>
              </li>
              <li className="step_arrow">
                <img src="/resources/images/arrow.png" alt="arrow" />
              </li>
              <li className="step">
                <span>SCENT</span>
                <span className="count">2</span>
              </li>
              <li className="step_arrow">
                <img src="/resources/images/arrow.png" alt="arrow" />
              </li>
            </ul>
            <ul className="steps">
              <li className="step">
                <span>SCENT</span>
                <span className="count">3</span>
              </li>
              <li className="step_arrow">
                <img src="/resources/images/arrow.png" alt="arrow" />
              </li>
              <li className="step">
                <span>SCENT</span>
                <span className="count">4</span>
              </li>
              <li className="step_arrow">
                <img src="/resources/images/arrow.png" alt="arrow" />
              </li>
              <li className="step">
                <span>SCENT</span>
                <span className="count">5</span>
              </li>
            </ul>
          </div>
        </section>
      </div>
      <footer>
        <p>
          <b>{dataConstant.footerMessage}</b>
        </p>
      </footer>
    </React.Fragment>
  );
};
export default ScanQRCode;
