import React, { useState, useEffect } from 'react';
import _, { isEqual } from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import { QuestionCircleOutlined, DatabaseOutlined, ReloadOutlined } from '@ant-design/icons';
import { EyeOutlined, CloseCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Select,
  Input,
  Form,
  Tooltip,
  Modal,
  Row,
  Tabs,
  Collapse,
  Slider,
  Col,
  notification,
  DatePicker,
} from 'antd';
import NewTableComponent from '../../../NewTableComponent';
import useDebounce from '../../../debouncedHook';
import messageConstant from '../../../constants/messageConstant';
import urlConstant from '../../../constants/urlConstant';
import commonService from '../../../services/commonService';
import TableComponent from '../../../TableComponent';
import dataConstant from '../../../constants/dataConstant';
const { Panel } = Collapse;
const { TabPane } = Tabs;

export default function TestResult() {
  const [searchForm] = Form.useForm();
  const [filterform] = Form.useForm();
  const dateFormat = dataConstant.Report.ReportDateFormat.dateFormat;
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const date = moment().format(dataConstant.dateFormat[1]);
  const [inputValue, setInputValue] = useState({
    min: 0,
    max: 0,
  });
  const [searchText, setSearchText] = useState('');
  let diseaseName = [];
  let userFamilyDiseaseName = [];

  const [testResultTableRequest, setTestResultTableRequest] = useState(
    JSON.parse(JSON.stringify(dataConstant.resultTableRequest))
  );
  const [testKitName, setTestKitName] = useState([]);
  const [organizationList, setOrganizationList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [refreshTable, setRefreshTable] = useState(Math.random());
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [testResultData, setTestResultData] = useState({
    testResultList: '',
    testResulTotalCount: '',
  });
  const [questionnaireData, setQuestionnaireData] = useState({
    questionnaireList: '',
    questionnaireTotalCount: '',
  });
  const [testAnswerResultDetail, setTestAnswerResultDetail] = useState({
    testAnswerResultData: '',
    testAnswerResultDataTotalCount: '',
  });
  // const [selectedRows, setSelectedRows] = useState('');

  const debouncedSearch = useDebounce(searchText, 1000);

  useEffect(() => {
    getTestResultList(testResultTableRequest);
  }, [JSON.stringify(testResultTableRequest)]);

  useEffect(() => {
    var pageRow = JSON.parse(localStorage.getItem('RowsPerPage'))
      ? JSON.parse(localStorage.getItem('RowsPerPage'))
      : 10;
    setTestResultTableRequest({
      ...testResultTableRequest,
      pageNumber: 1,
      SearchText: debouncedSearch,
      rowsPerPage: pageRow,
    });
    setPageNumber(1);
  }, [debouncedSearch]);

  useEffect(() => {
    getTestNameList();
    getOrganizationList();
    searchForm.setFieldsValue({
      ageRangeMin: 0,
      ageRangeMax: 0,
    });
  }, []);

  // Map User Disease within arry
  _.values(dataConstant.FilterOptionForUserMedication).map((item) => {
    diseaseName.push(item);
  });
  // Map User Family Disease within arry
  _.values(dataConstant.FilterOptionForUserFamilyMedication).map((item) => {
    userFamilyDiseaseName.push(item);
  });

  const getTestResultDataFilteredList = (requestObject, pagenumber = 1) => {
    setPageNumber(pagenumber);
    setTestResultTableRequest(requestObject);
    let modelData = JSON.parse(JSON.stringify(testResultTableRequest));
    if (!requestObject.SearchText) {
      filterform.resetFields();
    }
    setSearchText(requestObject.SearchText);
    if (requestObject) {
      modelData = requestObject;
      _.map(JSON.parse(JSON.stringify(testResultTableRequest)), (item, index) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!modelData.hasOwnProperty(index)) {
          modelData[index] = item;
        }
      });
    } else if (
      // eslint-disable-next-line no-dupe-else-if
      requestObject &&
      isEqual(requestObject, JSON.parse(JSON.stringify(dataConstant.resultTableRequest)))
    ) {
      setRefreshTable(Math.random());
      getTestResultList(requestObject);
      if (!requestObject.SearchText) {
        filterform.resetFields();
      }
    }
    setTestResultTableRequest(modelData);
  };

  const [tableRequestParamForTestDetail, setTableRequestParamForTestDetail] = useState({
    pageNumber: dataConstant.tableRequest.pageNumber,
    rowsPerPage: JSON.parse(localStorage.getItem('RowsPerPage'))
      ? JSON.parse(localStorage.getItem('RowsPerPage'))
      : dataConstant.resultTableRequest.rowsPerPage,
    SearchText: '',
    TestResultId: '',
    LoginOrganizationId: user.roleName === dataConstant.Role.Admin ? null : user.organizationId,
    filter: {
      ResultStatus: '',
      Latitude: '',
      Longitude: '',
    },
    sortColumns: dataConstant.tableRequest.sortColumns,
  });
  const questionnaireTableId = React.createRef();
  const testResultDetailTableId = React.createRef();

  const gridRefresh = () => {
    setSearchText('');
    // setSelectedRows(null);
    filterform.resetFields();
    let refreshTableData = testResultTableRequest;
    refreshTableData.pageNumber = dataConstant.tableRequest.pageNumber;
    refreshTableData.rowsPerPage = JSON.parse(localStorage.getItem('RowsPerPage'))
      ? JSON.parse(localStorage.getItem('RowsPerPage'))
      : dataConstant.resultTableRequest.rowsPerPage;
    refreshTableData.LoginOrganizationId =
      user.roleName === dataConstant.Role.Admin ? null : String(user.organizationId);
    refreshTableData.SearchText = '';
    refreshTableData.filter = {
      ResultStatus: '',
      Latitude: '',
      Longitude: '',
    };
    refreshTableData.sortColumns = dataConstant.tableRequest.sortColumns;
    setTestResultTableRequest(refreshTableData);
    getTestResultList(refreshTableData);
  };

  const getTestNameList = () => {
    commonService.httpGet(urlConstant.TestKit.GetTestNameList, true).then((response) => {
      if (response && response.data) {
        setTestKitName(response.data);
      }
    });
  };

  const getOrganizationList = () => {
    commonService.httpGet(urlConstant.Organization.DropDownOrganization, true).then((response) => {
      if (response && response.data) {
        setOrganizationList(response.data);
      }
    });
  };

  const getTestResultList = (requestObject) => {
    setIsLoaderVisible(true);
    requestObject.LoginOrganizationId = user.roleName === dataConstant.Role.Admin ? null : String(user.organizationId);
    commonService.httpPost(urlConstant.TestResult.GetTestResultList, requestObject, true).then((response) => {
      if (response && response.data) {
        setTestResultData({
          testResultList: response.data.list,
          testResulTotalCount: response.data.totalCount,
        });
        setIsLoaderVisible(false);
      }
    });
  };

  const getQuestionnaireResultList = (requestObject) => {
    const modelData = tableRequestParamForTestDetail;
    if (requestObject) {
      modelData.pageNumber = requestObject.pageNumber;
      modelData.rowsPerPage = requestObject.rowsPerPage;
      modelData.sortColumns = requestObject.sortColumns;
      modelData.LoginOrganizationId = user.roleName === dataConstant.Role.Admin ? null : String(user.organizationId);
      modelData.SearchText = requestObject.SearchText ? requestObject.SearchText : testResultTableRequest.SearchText;
    }
    commonService.httpPost(urlConstant.TestResult.GetQuestionnaireResultList, modelData, true).then((response) => {
      if (response && response.data) {
        setQuestionnaireData({
          questionnaireList: response.data.list,
          questionnaireTotalCount: response.data.totalCount,
        });
      }
    });
  };

  const getTestAnswerResultDetailList = (id, requestObject) => {
    const modelData = tableRequestParamForTestDetail;

    if (requestObject) {
      modelData.TestResultId = id;
      modelData.pageNumber = requestObject.pageNumber;
      modelData.rowsPerPage = requestObject.rowsPerPage;
      modelData.sortColumns = requestObject.sortColumns;
      modelData.LoginOrganizationId = user.roleName === dataConstant.Role.Admin ? null : String(user.organizationId);
      modelData.filter = requestObject.filter ? requestObject.filter : tableRequestParamForTestDetail.filter;
      modelData.SearchText = requestObject.SearchText
        ? requestObject.SearchText
        : tableRequestParamForTestDetail.SearchText;
    }
    commonService.httpPost(urlConstant.TestResult.GetTestAnswerResultDetailList, modelData, true).then((response) => {
      if (response && response.data) {
        setTestAnswerResultDetail({
          testAnswerResultData: response.data.list,
          testAnswerResultDataTotalCount: response.data.totalCount,
        });
      }
    });
  };

  const openViewModal = (id) => {
    getTestAnswerResultDetailList(id, tableRequestParamForTestDetail);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const Refresh = () => {
    setSearchText('');
    filterform.resetFields();
  };

  const DownloadCSV = () => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    let csvDataTableRequest = JSON.parse(JSON.stringify(testResultTableRequest));
    delete csvDataTableRequest[('pageNumber', 'rowsPerPage')];

    var raw = JSON.stringify(csvDataTableRequest);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(urlConstant.TestResult.ExportCsv, requestOptions)
      .then((response) => response.blob())
      .then((result) => {
        const url = URL.createObjectURL(new Blob([result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `MysafePassTestResult_${date}.csv`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log('error', error));
  };

  // const PdfDocument = (Props) => {
  //   const data = Props.data || [];
  //   return (
  //     <Document>
  //       {data.map((itemUser, i) => (
  //         <>
  //           <Page key={i}>
  //             <View
  //               style={{
  //                 alignSelf: 'center',
  //                 maxWidth: 340,
  //                 maxHeight: 240,
  //                 margin: 10,
  //               }}
  //             >
  //               <Image style={{}} src="/resources/images/neurological-logo.jpg" alt="logo" />
  //               <View
  //                 style={{
  //                   display: 'flex',
  //                   flexDirection: 'row',
  //                   justifyContent: 'flex-start',
  //                   alignItems: 'center',
  //                   marginTop: '5px',
  //                 }}
  //               >
  //                 <Text
  //                   style={{
  //                     fontSize: 16,
  //                     textAlign: 'right',
  //                     paddingRight: '10px',
  //                   }}
  //                 >
  //                   TimeStamp (UTC) :
  //                 </Text>
  //                 <Text
  //                   style={{
  //                     fontSize: 16,
  //                     textAlign: 'left',
  //                     width: '70%',
  //                   }}
  //                 >
  //                   {moment.utc(itemUser.created).toISOString()}
  //                   {/* {dateInUtc} */}
  //                 </Text>
  //               </View>
  //             </View>
  //             <View
  //               style={{
  //                 marginBottom: 8,
  //                 padding: 8,
  //                 paddingLeft: 15,
  //               }}
  //             >
  //               <View
  //                 style={{
  //                   display: 'flex',
  //                   flexDirection: 'row',
  //                   justifyContent: 'flex-start',
  //                   alignItems: 'center',
  //                   marginBottom: '15px',
  //                 }}
  //               >
  //                 <Text
  //                   style={{
  //                     fontSize: 16,
  //                     width: '30%',
  //                     textAlign: 'right',
  //                     paddingRight: '10px',
  //                     color: '#000000',
  //                   }}
  //                 >
  //                   First Name :
  //                 </Text>
  //                 <Text
  //                   style={{
  //                     fontSize: 16,
  //                     textAlign: 'left',
  //                     color: '#000000',
  //                     width: '70%',
  //                   }}
  //                 >
  //                   {itemUser.firstName}
  //                 </Text>
  //               </View>
  //               <View
  //                 style={{
  //                   display: 'flex',
  //                   flexDirection: 'row',
  //                   justifyContent: 'flex-start',
  //                   alignItems: 'center',
  //                   marginBottom: '15px',
  //                 }}
  //               >
  //                 <Text
  //                   style={{
  //                     fontSize: 16,
  //                     width: '30%',
  //                     textAlign: 'right',
  //                     paddingRight: '10px',
  //                     color: '#000000',
  //                   }}
  //                 >
  //                   Last Name :
  //                 </Text>
  //                 <Text
  //                   style={{
  //                     fontSize: 16,
  //                     textAlign: 'left',
  //                     color: '#000000',
  //                     width: '70%',
  //                   }}
  //                 >
  //                   {itemUser.lastName}
  //                 </Text>
  //               </View>
  //               <View
  //                 style={{
  //                   display: 'flex',
  //                   flexDirection: 'row',
  //                   justifyContent: 'flex-start',
  //                   alignItems: 'center',
  //                   marginBottom: '15px',
  //                 }}
  //               >
  //                 <Text
  //                   style={{
  //                     fontSize: 16,
  //                     width: '30%',
  //                     textAlign: 'right',
  //                     paddingRight: '10px',
  //                     color: '#000000',
  //                   }}
  //                 >
  //                   Gender :
  //                 </Text>
  //                 <Text
  //                   style={{
  //                     fontSize: 16,
  //                     textAlign: 'left',
  //                     color: '#000000',
  //                     width: '70%',
  //                   }}
  //                 >
  //                   {itemUser.gender}
  //                 </Text>
  //               </View>
  //               <View
  //                 style={{
  //                   display: 'flex',
  //                   flexDirection: 'row',
  //                   justifyContent: 'flex-start',
  //                   alignItems: 'center',
  //                   marginBottom: '15px',
  //                 }}
  //               >
  //                 <Text
  //                   style={{
  //                     fontSize: 16,
  //                     width: '30%',
  //                     textAlign: 'right',
  //                     paddingRight: '10px',
  //                     color: '#000000',
  //                   }}
  //                 >
  //                   Age :
  //                 </Text>
  //                 <Text
  //                   style={{
  //                     fontSize: 16,
  //                     textAlign: 'left',
  //                     color: '#000000',
  //                     width: '70%',
  //                   }}
  //                 >
  //                   {itemUser.age}
  //                 </Text>
  //               </View>
  //               <View
  //                 style={{
  //                   display: 'flex',
  //                   flexDirection: 'row',
  //                   justifyContent: 'flex-start',
  //                   alignItems: 'center',
  //                   marginBottom: '15px',
  //                 }}
  //               >
  //                 <Text
  //                   style={{
  //                     fontSize: 16,
  //                     textAlign: 'right',
  //                     width: '30%',
  //                     paddingRight: '10px',
  //                     color: '#000000',
  //                   }}
  //                 >
  //                   Race / Ethnicity :
  //                 </Text>
  //                 <Text
  //                   style={{
  //                     fontSize: 16,
  //                     textAlign: 'left',
  //                     paddingRight: '10px',
  //                     color: '#000000',
  //                     width: '70%',
  //                   }}
  //                 >
  //                   {itemUser.race}
  //                 </Text>
  //               </View>
  //               <View
  //                 style={{
  //                   display: 'flex',
  //                   flexDirection: 'row',
  //                   justifyContent: 'flex-start',
  //                   alignItems: 'center',
  //                   marginBottom: '15px',
  //                 }}
  //               >
  //                 <Text
  //                   style={{
  //                     fontSize: 16,
  //                     textAlign: 'right',
  //                     width: '30%',
  //                     paddingRight: '10px',
  //                     color: '#000000',
  //                   }}
  //                 >
  //                   Disorder :
  //                 </Text>
  //                 <Text
  //                   style={{
  //                     fontSize: 16,
  //                     textAlign: 'left',
  //                     paddingRight: '10px',
  //                     color: '#000000',
  //                     width: '70%',
  //                   }}
  //                 >
  //                   {itemUser.userDisease}
  //                 </Text>
  //               </View>
  //               <View
  //                 style={{
  //                   display: 'flex',
  //                   flexDirection: 'row',
  //                   justifyContent: 'flex-start',
  //                   alignItems: 'center',
  //                   marginBottom: '15px',
  //                 }}
  //               >
  //                 <Text
  //                   style={{
  //                     fontSize: 16,
  //                     textAlign: 'right',
  //                     width: '30%',
  //                     paddingRight: '10px',
  //                     color: '#000000',
  //                   }}
  //                 >
  //                   Family History Disease:
  //                 </Text>
  //                 <Text
  //                   style={{
  //                     fontSize: 16,
  //                     textAlign: 'left',
  //                     paddingRight: '10px',
  //                     color: '#000000',
  //                     width: '70%',
  //                   }}
  //                 >
  //                   {itemUser.userFamilyDisease}
  //                 </Text>
  //               </View>
  //               <View
  //                 style={{
  //                   display: 'flex',
  //                   flexDirection: 'row',
  //                   justifyContent: 'flex-start',
  //                   alignItems: 'center',
  //                   marginBottom: '15px',
  //                 }}
  //               >
  //                 <Text
  //                   style={{
  //                     fontSize: 16,
  //                     textAlign: 'right',
  //                     paddingRight: '10px',
  //                     color: '#000000',
  //                     width: '30%',
  //                   }}
  //                 >
  //                   Organization / Medical Provider :
  //                 </Text>
  //                 <Text
  //                   style={{
  //                     fontSize: 16,
  //                     textAlign: 'left',
  //                     paddingRight: '10px',
  //                     color: '#000000',
  //                     width: '70%',
  //                   }}
  //                 >
  //                   {itemUser.organizationName}
  //                 </Text>
  //               </View>
  //               <View
  //                 style={{
  //                   display: 'flex',
  //                   flexDirection: 'row',
  //                   justifyContent: 'flex-start',
  //                   alignItems: 'center',
  //                   marginBottom: '15px',
  //                 }}
  //               >
  //                 <Text
  //                   style={{
  //                     fontSize: 16,
  //                     textAlign: 'right',
  //                     paddingRight: '10px',
  //                     color: '#000000',
  //                     width: '30%',
  //                   }}
  //                 >
  //                   Test Kit :
  //                 </Text>
  //                 <Text
  //                   style={{
  //                     fontSize: 16,
  //                     textAlign: 'left',
  //                     paddingRight: '10px',
  //                     color: '#000000',
  //                     width: '70%',
  //                   }}
  //                 >
  //                   {itemUser.testKitName}
  //                 </Text>
  //               </View>
  //               <View
  //                 style={{
  //                   display: 'flex',
  //                   flexDirection: 'row',
  //                   justifyContent: 'flex-start',
  //                   alignItems: 'center',
  //                   marginBottom: '15px',
  //                 }}
  //               >
  //                 <Text
  //                   style={{
  //                     fontSize: 16,
  //                     textAlign: 'right',
  //                     paddingRight: '10px',
  //                     color: '#000000',
  //                     width: '30%',
  //                   }}
  //                 >
  //                   Result :
  //                 </Text>
  //                 <Text
  //                   style={{
  //                     fontSize: 16,
  //                     textAlign: 'left',
  //                     paddingRight: '10px',
  //                     color: '#000000',
  //                     width: '70%',
  //                   }}
  //                 >
  //                   {itemUser.resultStatus}
  //                 </Text>
  //               </View>
  //             </View>
  //           </Page>
  //         </>
  //       ))}
  //     </Document>
  //   );
  // };

  // const DownloadPDF = (e) => {
  //   if (!selectedRows) {
  //     notification.warning({
  //       message: dataConstant.APIStatus.Warning,
  //       description: 'Please select atleast one row',
  //     });
  //     return;
  //   }
  // };

  // const onSelectChange = (key, selectedRows) => {
  //   if (key.length > 0) {
  //     setSelectedRows(selectedRows);
  //   } else {
  //     setSelectedRows('');
  //   }
  // };

  const columns = [
    {
      title: 'Action',
      dataIndex: 'action',
      fixed: true,
      align: 'center',
      width: 50,
      render: (text, record) => (
        <span>
          <Tooltip placement="bottom" title="View">
            <Button icon={<EyeOutlined />} className="mr-1" size="small" onClick={() => openViewModal(record.id)} />
          </Tooltip>
        </span>
      ),
    },
    {
      title: 'FirstName',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      width: 100,
      dataType: 'StringContains',
      hidden: user.roleName === dataConstant.Role.ClientAdmin && user.discloseUserDetails === false ? true : false,
    },
    {
      title: 'LastName',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: true,
      width: 100,
      dataType: 'StringContains',
      hidden: user.roleName === dataConstant.Role.ClientAdmin && user.discloseUserDetails === false ? true : false,
    },
    {
      title: 'Date of Birth',
      dataIndex: 'dob',
      key: 'dob',
      sorter: true,
      width: 120,
      dataType: 'StringContains',
      hidden: user.roleName === dataConstant.Role.ClientAdmin && user.discloseUserDetails === false ? true : false,
      render: (dob) => (dob ? moment(dob).format(dataConstant.Report.ReportDateFormat.dateFormat) : <span>-</span>),
    },
    {
      title: 'Insurance Provider Name',
      dataIndex: 'insuranceProviderName',
      key: 'insuranceProviderName',
      sorter: true,
      width: 150,
      dataType: 'StringContains',
      hidden: user.roleName === dataConstant.Role.ClientAdmin && user.discloseUserDetails === false ? true : false,
    },
    {
      title: 'Insurance Id',
      dataIndex: 'insuranceId',
      key: 'insuranceId',
      sorter: true,
      width: 150,
      dataType: 'StringContains',
      hidden: user.roleName === dataConstant.Role.ClientAdmin && user.discloseUserDetails === false ? true : false,
    },
    {
      title: 'Test Kit',
      dataIndex: 'testKitName',
      key: 'testKitName',
      sorter: true,
      width: 150,
      dataType: 'StringContains',
    },
    {
      title: 'Organization',
      dataIndex: 'organizationName',
      key: 'organizationName',
      sorter: true,
      width: 150,
      dataType: 'StringContains',
      render: (Organization) => (
        <span>{Organization === null || Organization === '' ? <span>-</span> : <span>{Organization}</span>}</span>
      ),
    },
    {
      title: 'Result',
      dataIndex: 'resultStatus',
      key: 'resultStatus',
      sorter: true,
      width: 150,
      dataType: 'StringContains',
    },
    {
      title: 'Right Answer Count',
      dataIndex: 'answerStatus',
      key: 'answerStatus',
      sorter: true,
      width: 180,
      dataType: 'StringContains',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      sorter: true,
      width: 150,
      dataType: 'StringContains',
      render: (Age) => <span>{Age === null || Age === '' ? <span>-</span> : <span>{Age}</span>}</span>,
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      width: 150,
      sorter: true,
      dataType: 'StringContains',
      render: (Gender) => <span>{Gender === null || Gender === '' ? <span>-</span> : <span>{Gender}</span>}</span>,
    },
    {
      title: 'Race/Ethnicity',
      dataIndex: 'race',
      key: 'race',
      sorter: true,
      width: 150,
      dataType: 'StringContains',
      render: (Race) => <span>{Race === null || Race === '' ? <span>-</span> : <span>{Race}</span>}</span>,
    },
    {
      title: 'Disorders',
      dataIndex: ['userDisease'],
      key: 'userDisease',
      width: 200,
      dataType: 'StringContains',
      render: (Disease) => <span>{Disease === null || Disease === '' ? <span>-</span> : <span>{Disease}</span>}</span>,
    },
    {
      title: 'Family History - Disease',
      dataIndex: ['userFamilyDisease'],
      key: 'userFamilyDisease',
      width: 200,
      dataType: 'StringContains',
      render: (Disease) => <span>{Disease === null || Disease === '' ? <span>-</span> : <span>{Disease}</span>}</span>,
    },
    {
      title: 'Latitude',
      dataIndex: 'latitude',
      key: 'latitude',
      sorter: true,
      width: 200,
      dataType: 'StringContains',
      render: (Latitude) => (
        <span>
          {Latitude === null || Latitude === '' ? <span>-</span> : <span>{parseFloat(Latitude).toFixed(5)}</span>}
        </span>
      ),
    },
    {
      title: 'Longitude',
      dataIndex: 'longitude',
      key: 'longitude',
      sorter: true,
      width: 200,
      dataType: 'StringContains',
      render: (Longitude) => (
        <span>
          {Longitude === null || Longitude === '' ? <span>-</span> : <span>{parseFloat(Longitude).toFixed(5)}</span>}
        </span>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'created',
      key: 'created',
      sorter: true,
      width: 200,
      dataType: 'StringContains',
      render: (date) => moment.tz(date, 'America/Los_Angeles').format('MM/DD/yyyy, h:mm:ss A'),
    },
  ].filter((item) => !item.hidden);

  const testResultDetailColumns = [
    {
      title: 'Scent Card Name',
      dataIndex: 'scentCardName',
      key: 'scentCardName',
      width: 200,
      dataType: 'StringContains',
    },
    {
      title: 'Answers',
      dataIndex: 'answerName',
      key: 'answerName',
      width: 200,
      dataType: 'StringContains',
    },
    {
      title: 'Smell Intensity',
      dataIndex: 'smellIntensityText',
      key: 'smellIntensityText',
      width: 200,
      dataType: 'StringContains',
    },
    {
      title: 'Answer Status',
      dataIndex: 'answerStatus',
      key: 'answerStatus',
      width: 200,
      dataType: 'StringContains',
    },
  ];

  const questionnaireColumns = [
    {
      title: 'Questions',
      dataIndex: 'question',
      key: 'question',
      sorter: true,
      width: 400,
      dataType: 'StringContains',
    },
    {
      title: 'Answers',
      dataIndex: 'answer',
      key: 'answer',
      sorter: true,
      width: 100,
      dataType: 'StringContains',
    },
  ];

  const searchTestResult = (e, requestObject) => {
    const modelData = JSON.parse(JSON.stringify(testResultTableRequest));
    if (requestObject) {
      modelData.TestKitId = e.testKitName;
      modelData.ResultStatus = e.answerStatus === undefined ? '' : e.answerStatus;
      modelData.OrganizationId = e.organizationName;
      modelData.pageNumber = dataConstant.tableRequest.pageNumber;
      modelData.rowsPerPage = requestObject.rowsPerPage;
      modelData.sortColumns = requestObject.sortColumns;
      modelData.LoginOrganizationId = user.roleName === dataConstant.Role.Admin ? null : String(user.organizationId);
      modelData.Gender = e.gender === undefined ? '' : e.gender;
      modelData.Race = e.race;
      modelData.UserFamilyDisease = e.userFamilyDisease;
      modelData.UserDisease = e.userDisease;
      modelData.AgeRangeMax = e.ageRangeMax;
      modelData.AgeRangeMin = e.ageRangeMin;
      modelData.SearchText = requestObject.SearchText ? requestObject.SearchText : testResultTableRequest.SearchText;
      if (e.fromDate != null && e.toDate != null && e.fromDate >= e.toDate) {
        notification.warning({
          description: messageConstant.Report.DateValidation,
        });
        return;
      }

      modelData.FromDate = e.fromDate ? e.fromDate.format(dataConstant.Report.ReportDateFormat.dateFormat) : null;
      modelData.ToDate = e.toDate ? e.toDate.format(dataConstant.Report.ReportDateFormat.dateFormat) : null;
    }
    setTestResultTableRequest(modelData);
    getTestResultDataFilteredList(modelData);
  };

  const onChange = (key) => {
    if (key === '2') {
      getQuestionnaireResultList(tableRequestParamForTestDetail);
    }
  };

  return (
    <>
      <div className="page-header">
        <h1>Test Result</h1>
        <Collapse>
          <Panel header="Test Result Filter" key="1">
            <div className="cm-filter-wrapper cm-test-filer-wrapper">
              <Form
                form={searchForm}
                onFinish={(e) => searchTestResult(e, testResultTableRequest)}
                className="row cm-country-form"
              >
                <Form.Item
                  className={user.roleName === dataConstant.Role.Admin ? 'col-md-3 mb-4' : 'col-md-3 mb-4'}
                  id="testKitName"
                  name="testKitName"
                  label="Test Kit Name"
                >
                  <Select
                    mode="multiple"
                    allowClear
                    style={{
                      width: '100%',
                    }}
                    filterOption={(input, option) =>
                      option.children.toString().toLowerCase().includes(input.toString().toLowerCase())
                    }
                    placeholder="Please Select Test Kit"
                  >
                    {_.map(testKitName.length > 0 && testKitName, (item) => {
                      return (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  className={user.roleName === dataConstant.Role.Admin ? 'col-md-3 mb-4' : 'col-md-3 mb-4'}
                  id="answerStatus"
                  name="answerStatus"
                  label="Answer Status"
                >
                  <Select
                    allowClear
                    style={{
                      width: '100%',
                    }}
                    placeholder="Please Select Answer Status"
                  >
                    {_.map(dataConstant.TestResultStatus, (item) => {
                      return (
                        <Select.Option key={item.value} value={item.value}>
                          {item.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                {user.roleName === dataConstant.Role.Admin && (
                  <Form.Item
                    className="col-md-3 mb-4"
                    id="organizationName"
                    name="organizationName"
                    label="Organization"
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{
                        width: '100%',
                      }}
                      filterOption={(input, option) =>
                        option.children.toString().toLowerCase().includes(input.toString().toLowerCase())
                      }
                      placeholder="Please Select Organization"
                    >
                      {_.map(organizationList.length > 0 && organizationList, (item) => {
                        return (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name} - {item.code}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )}
                <Form.Item
                  className={user.roleName === dataConstant.Role.Admin ? 'col-md-3 mb-4' : 'col-md-3 mb-4'}
                  id="race"
                  name="race"
                  label="Race/Ethnicity"
                >
                  <Select
                    mode="multiple"
                    allowClear
                    style={{
                      width: '100%',
                    }}
                    placeholder="Please Select Race/Ethnicity"
                  >
                    {_.map(dataConstant.Race, (item) => {
                      return (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  className={user.roleName === dataConstant.Role.Admin ? 'col-md-3 mb-2' : 'col-md-3 mb-2'}
                  id="gender"
                  name="gender"
                  label="Gender"
                >
                  <Select
                    allowClear
                    style={{
                      width: '100%',
                    }}
                    placeholder="Please Select Gender"
                  >
                    {_.map(dataConstant.Gender, (item) => {
                      return (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item className="col-md-3 mb-2">
                  <div className="row align-items-center">
                    <div className="text-left col-md-3">
                      <div className="ant-form-item-label custom_label">
                        <label>Age</label>
                      </div>
                    </div>
                    <div className="col-md-7 col-9 pl-sm-0">
                      <Slider
                        range={{
                          draggableTrack: true,
                        }}
                        min={1}
                        max={100}
                        onChange={(newValue) => {
                          setInputValue({
                            min: typeof newValue[0] === 'number' ? newValue[0] : parseInt(newValue[0].target.value),
                            max: typeof newValue[1] === 'number' ? newValue[1] : parseInt(newValue[1].target.value),
                          });
                          searchForm.setFieldsValue({
                            ageRangeMin: typeof newValue[0] === 'number' ? newValue[0] : newValue[0].target.value,
                            ageRangeMax: typeof newValue[1] === 'number' ? newValue[1] : newValue[1].target.value,
                          });
                        }}
                        value={[
                          typeof inputValue.min === 'number' ? inputValue.min : 0,
                          typeof inputValue.max === 'number' ? inputValue.max : 0,
                        ]}
                      />
                    </div>
                    <div className="col-md-2 col-3 pl-0">
                      <Button
                        className="btn btn-outline-secondary reset_btn"
                        onClick={() => {
                          setInputValue({ min: 0, max: 0 });
                          searchForm.setFieldsValue({ ageRangeMin: 0, ageRangeMax: 0 });
                        }}
                      >
                        <ReloadOutlined />
                      </Button>
                    </div>
                  </div>
                  <div>
                    <Row style={{ marginBottom: 8, alignItems: 'start' }}>
                      <Col span={12}>
                        <Form.Item
                          className="w-100 px-0"
                          name="ageRangeMin"
                          validateTrigger="onChange"
                          rules={[
                            { required: true, message: 'Please Enter Age.' },
                            {
                              message: 'Min. Age range must be less than Max. Age range',
                              validator: (_, value) => {
                                if (value > inputValue.max) {
                                  throw new Error('Min. Age range must be less than Max. Age range');
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            className="w-100"
                            min={0}
                            max={100}
                            value={inputValue.min}
                            defaultValue={inputValue.min}
                            onChange={(newValue) => {
                              setInputValue((prevData) => ({
                                ...prevData,
                                min: typeof newValue === 'number' ? newValue : parseInt(newValue.target.value),
                              }));
                              searchForm.setFieldsValue({
                                ageRangeMin: typeof newValue === 'number' ? newValue : parseInt(newValue.target.value),
                              });
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          className="w-100 px-0"
                          name="ageRangeMax"
                          validateTrigger="onChange"
                          rules={[
                            { required: true, message: 'Please Enter Age.' },
                            {
                              message: 'Max. Age range must be greater than Min. Age range',
                              validator: (_, value) => {
                                if (value < inputValue.min) {
                                  throw new Error('Max. Age range must be greater than Min. Age range');
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            className="w-100"
                            min={0}
                            max={100}
                            value={inputValue.max}
                            defaultValue={inputValue.max}
                            onChange={(newValue) => {
                              setInputValue((prevData) => ({
                                ...prevData,
                                max: typeof newValue === 'number' ? newValue : parseInt(newValue.target.value),
                              }));
                              searchForm.setFieldsValue({
                                ageRangeMax: typeof newValue === 'number' ? newValue : parseInt(newValue.target.value),
                              });
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Form.Item>

                <Form.Item
                  className={user.roleName === dataConstant.Role.Admin ? 'col-md-3 mb-2' : 'col-md-3 mb-2'}
                  id="userDisease"
                  name="userDisease"
                  label="Disorders"
                >
                  <Select
                    mode="multiple"
                    allowClear
                    style={{
                      width: '100%',
                    }}
                    placeholder="Please Select User Disease"
                  >
                    {_.map(dataConstant.FilterOptionForUserMedication, (item, index) => {
                      return (
                        <Select.Option key={item} value={index}>
                          {item}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  className={user.roleName === dataConstant.Role.Admin ? 'col-md-3 mb-2' : 'col-md-3 mb-2'}
                  id="userFamilyDisease"
                  name="userFamilyDisease"
                  label="Family History - Disease"
                >
                  <Select
                    mode="multiple"
                    allowClear
                    style={{
                      width: '100%',
                    }}
                    placeholder="Please Select Family Disease"
                  >
                    {_.map(dataConstant.FilterOptionForUserFamilyMedication, (item, index) => {
                      return (
                        <Select.Option key={item} value={index}>
                          {item}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item className="col-md-6 p-0">
                  <div className="d-flex date_picker_wrapper">
                    <Form.Item id="fromDate" name="fromDate" label="From">
                      <DatePicker
                        format={dateFormat}
                        name="fromDate"
                        disabledDate={(current) => {
                          return current && current > moment().endOf('day');
                        }}
                      />
                    </Form.Item>
                    <Form.Item id="toDate" name="toDate" label="To">
                      <DatePicker
                        format={dateFormat}
                        disabledDate={(current) => {
                          return current && current < searchForm.getFieldValue('fromDate');
                        }}
                      />
                    </Form.Item>
                  </div>
                </Form.Item>

                <Form.Item className="col-md-6 text-right mt-5">
                  <Button className="btn btn-outline-secondary" type="primary" htmlType="submit">
                    Search
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Panel>
        </Collapse>
        <div className="row align-items-end justify-content-between cm-filter-wrapper cm-test-filer-wrapper mt-4">
          <Form form={filterform} className="cm-search col-md-5">
            <Row className="align-items-end">
              <Form.Item id="data" label="Search" name="searchtext" className="col-12 m-0">
                <Input placeholder="Search" onChange={(e) => setSearchText(e.target.value)} />
              </Form.Item>
              <Button icon={<CloseCircleOutlined />} onClick={() => gridRefresh()} htmlType="submit" type="primary" />
            </Row>
          </Form>
          <Modal
            title="Test Answer Detail"
            visible={isModalVisible}
            onCancel={closeModal}
            width="1000px"
            bodyStyle={{ height: 'auto' }}
            maskClosable={false}
            footer={null}
            destroyOnClose
          >
            <Tabs defaultActiveKey="1" tabPosition="top" onChange={onChange}>
              <TabPane
                tab={
                  <span>
                    <DatabaseOutlined />
                    Test Answer Detail
                  </span>
                }
                key="1"
              >
                <TableComponent
                  ref={testResultDetailTableId}
                  rowKey="id"
                  columns={testResultDetailColumns}
                  dataSource={testAnswerResultDetail.testAnswerResultData}
                  getGridData={getTestAnswerResultDetailList}
                  totalCount={testAnswerResultDetail.testAnswerResultDataTotalCount}
                  scroll={{ x: 'max-content' }}
                  loading={false}
                  refresh={(current) => Refresh(current)}
                />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <QuestionCircleOutlined />
                    Questionnaire Answer Detail
                  </span>
                }
                key="2"
              >
                <TableComponent
                  ref={questionnaireTableId}
                  rowKey="question"
                  columns={questionnaireColumns}
                  dataSource={questionnaireData.questionnaireList}
                  getGridData={getQuestionnaireResultList}
                  totalCount={questionnaireData.questionnaireTotalCount}
                  scroll={{ x: 'max-content' }}
                  loading={false}
                />
              </TabPane>
            </Tabs>
          </Modal>
          <div className="col-md-3 mt-4 mt-sm-0 text-sm-right ">
            {/* {!selectedRows ? (
              <Button className="btn btn-outline-secondary mr-3" onClick={() => DownloadPDF()}>
                Download Result
              </Button>
            ) : (
              <PDFDownloadLink
                document={<PdfDocument data={selectedRows} />}
                fileName={`MysafePassResult${date}.pdf`}PdfDocument
                className={'next-btn'}
              >
                <Button className="btn btn-outline-secondary mr-3">Download Result</Button>
              </PDFDownloadLink>
            )} */}

            <Button className="btn btn-outline-secondary" onClick={() => DownloadCSV()}>
              Export
            </Button>
          </div>
        </div>
      </div>

      <NewTableComponent
        key={refreshTable}
        rowKey="Id"
        columns={columns}
        dataSource={testResultData.testResultList}
        getGridData={getTestResultDataFilteredList}
        tableRequest={JSON.parse(JSON.stringify(testResultTableRequest))}
        totalCount={testResultData.testResulTotalCount}
        scroll={{ x: 'max-content' }}
        refresh={Refresh}
        loading={isLoaderVisible}
        SearchText={searchText}
        pageNumber={pageNumber}
        // enableRowSelection
        // onSelectChange={onSelectChange}
        // isShowMultiDelete={false}
      />
    </>
  );
}
