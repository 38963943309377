import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { Modal, Radio, Spin, notification } from 'antd';
import { httpGet, httpPost } from '../../services/commonService';
import urlConstant from '../../constants/urlConstant';
import useGeoLocation from '../../Test/useGeoLocation';
import dataConstant from '../../constants/dataConstant';
import messageConstant from '../../constants/messageConstant';

const Scent = ({
  match: {
    params: { id },
  },
}) => {
  const history = useHistory();
  const geoLocation = useGeoLocation();

  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [smellIntensityModalVisible, setSmellIntensityModalVisible] = useState(false);
  const [smellIntensityValue, setSmellIntensityValue] = useState();
  const [scentCardList, setScentCardList] = useState([]);
  const [selectedScentCard, setSelectedScentCard] = useState(0);
  const [scentCardOptions, setScentCardOptions] = useState();
  const [selectedCardAnswer, setSelectedCardAnswer] = useState();
  const [finalTestResult, setFinalTestResult] = useState({
    TestKitId: null,
    Latitude: null,
    Longitude: null,
    // UserName: null,
    FirstName: null,
    LastName: null,
    OrganizationId: null,
    TestResultDetails: [],
    DOB: null,
    InsuranceProviderName: '',
    InsuranceId: null,
  });

  const [imageURL, setImageURL] = useState([0, 1, 2, 3, 4]);
  const imageRef = React.useRef(imageURL && imageURL.map(() => React.createRef()));

  /** Get scent card detail on page load */
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!localStorage.getItem('safePassData')) {
      history.push('/home');
      return;
    }
    const MySafePassData = JSON.parse(localStorage.getItem('safePassData'));
    if (MySafePassData && (!MySafePassData.FirstName || !MySafePassData.LastName)) history.push('/home');

    getScentCardDetails();
  }, []);

  /** Get Scent Card Details and Options List */
  const getScentCardDetails = () => {
    setIsLoaderVisible(true);
    httpGet(`${urlConstant.ScentCard.GetSentCardDetails}${id}`)
      .then((response) => {
        if (response) {
          if (response.status === dataConstant.APIStatus.Success) {
            const sortScentCardList = _.sortBy(response.data, 'scentCardSortOrder');
            setScentCardList(sortScentCardList);
            setSmellIntensityValue(null);
            setSelectedScentCard(1);
            setIsLoaderVisible(false);
          } else if (response.status === dataConstant.APIStatus.Failure) {
            notification.error({
              message: dataConstant.NotificationType.Error,
              description: messageConstant.InvalidQRCode,
            });
            history.push({ pathname: '/scanQRCode' });
            setIsLoaderVisible(false);
          } else {
            uncaughtErrorHandler();
          }
        } else {
          uncaughtErrorHandler();
        }
      })
      .catch((error) => {
        uncaughtErrorHandler();
      });
  };

  /** Filter selected card options from scentCardList */
  useEffect(() => {
    if (scentCardList && scentCardList.length > 0 && selectedScentCard > 0) {
      const cardOptions = scentCardList.filter((item) => item.scentCardSortOrder === selectedScentCard);
      setScentCardOptions(_.sortBy(cardOptions, 'scentCardOptionsSortOrder'));
    }
  }, [scentCardList, selectedScentCard]);

  /** If any uncaught error throws then redirect to home page */
  const uncaughtErrorHandler = () => {
    setIsLoaderVisible(false);
    notification.error({
      message: dataConstant.NotificationType.Error,
      description: messageConstant.SomethingWentWrong,
    });
    history.push({ pathname: '/home' });
  };

  /** Close Smell Intensity Popup and set default Mild as active */
  const openSmellIntensityPopup = (selectedCardOption) => {
    if (selectedCardOption.scentCardOptionsName === 'No Scent') {
      setSmellIntensityValue(0);
      onChangeSmellIntensityValue();
    } else {
      setSmellIntensityModalVisible(true);
    }
    setSelectedCardAnswer(selectedCardOption.scentCardOptionDetailId);
  };

  /** Close Smell Intensity Popup and set default Mild as active */
  const cancelSmellIntensityPopup = () => {
    setSmellIntensityModalVisible(false);
    setSmellIntensityValue(0);
    setSelectedCardAnswer();
  };

  /** Select Smell Intensity by Mild, Medium, Strong, Intense */
  const onSelectSmellIntensity = (e) => {
    setSmellIntensityValue(e.target.value);
  };

  /** Hook calls when state value of selected intensity changed */
  useEffect(() => {
    if (smellIntensityValue) {
      onChangeSmellIntensityValue();
    }
  }, [smellIntensityValue]);

  /** Close modal popup when selected smell intensity value saved in state variable */
  const onChangeSmellIntensityValue = () => {
    setSmellIntensityModalVisible(false);
    scentCardOptions.map((item) => {
      imageRef.current.forEach((ref) => {
        if (ref.current.id === item.scentCardOptionsId.toString()) {
          ref.current.removeAttribute('src');
        }
      });
    });
    const scentCardId = _.first(scentCardOptions).scentCardId;

    let result = Object.assign({}, finalTestResult);

    /** IF all step completed then add detail of test kit, lat and long */
    if (selectedScentCard === 5) {
      let TestKitId = 0;
      if (scentCardList && scentCardList.length > 0) {
        TestKitId = _.first(scentCardList).testKitId;
      }

      const safePassData = localStorage.getItem('safePassData');
      let userData = safePassData ? JSON.parse(safePassData) : {};
      result = {
        ...result,
        TestKitId: TestKitId,
        OrganizationId: userData ? userData.organizationId : null,
        Latitude: geoLocation.coordinates.lat.toString(),
        Longitude: geoLocation.coordinates.lng.toString(),
        // UserName: userData.name,
        FirstName: userData.FirstName.trim(),
        LastName: userData.LastName.trim(),
        DOB: userData.DOB,
        InsuranceProviderName: userData.insuranceProviderName,
        InsuranceId: userData.insuranceId,
      };
    }

    /** Update test result object with selected card, selected answer and smell intensity detail */
    setFinalTestResult({
      ...result,
      TestResultDetails: [
        ...result.TestResultDetails,
        {
          ScentCardsId: scentCardId,
          AnswerId: selectedCardAnswer,
          SmellIntensity: smellIntensityValue,
        },
      ],
    });

    /** If it is not last card then increment card with one to move next card */
    if (selectedScentCard < 5) {
      setSelectedScentCard(selectedScentCard + 1);
    }
    setSmellIntensityValue(0);
    setSelectedCardAnswer();
  };

  /** Once all step completed and state variable updated with last result then call save result function */
  useEffect(() => {
    if (
      selectedScentCard === 5 &&
      finalTestResult.TestResultDetails &&
      finalTestResult.TestResultDetails.length === 5
    ) {
      saveFinalResult();
    }
  }, [finalTestResult]);

  /** Save result of test into DB */
  const saveFinalResult = () => {
    setIsLoaderVisible(true);
    httpPost(urlConstant.TestResult.SaveTestResult, finalTestResult)
      .then((response) => {
        if (response) {
          if (response.status === dataConstant.APIStatus.Success) {
            localStorage.setItem('testResultData', JSON.stringify(response.data));
            setIsLoaderVisible(false);
            history.replace({ pathname: `/userMedicationQuestion` });
          } else if (response.status === dataConstant.APIStatus.Failure) {
            setIsLoaderVisible(false);
            notification.error({
              message: dataConstant.NotificationType.Error,
              description: messageConstant.InvalidQRCode,
            });
            history.push({ pathname: '/scanQRCode' });
          } else {
            uncaughtErrorHandler();
          }
        } else {
          uncaughtErrorHandler();
        }
      })
      .catch((error) => {
        uncaughtErrorHandler();
      });
  };

  return (
    <React.Fragment>
      <Spin tip="Loading..." size="large" spinning={isLoaderVisible}>
        <div className="scent_page">
          <header className="d-flex align-items-center justify-content-between">
            <a
              className="back-btn"
              onClick={() => {
                history.push({
                  pathname: '/home',
                });
              }}
            >
              <img src="/resources/images/back_arrow_white.png" alt="arrow" />
            </a>
            <a className="logo" href="#">
              <img src="/resources/images/logo-white.png" alt="logo" />
            </a>
          </header>
          <div className="scent_body">
            <section className="scent_items">
              {scentCardOptions && scentCardOptions.length > 0 && (
                <div className="row justify-content-between mx-0">
                  {scentCardOptions.map((item, index) => (
                    <div className={index === 2 ? 'col-12' : 'col-5'} key={index}>
                      <div
                        className={
                          selectedCardAnswer === item.scentCardOptionDetailId ? 'scent_item active' : 'scent_item'
                        }
                        onClick={() => openSmellIntensityPopup(item)}
                        id={item.scentCardOptionDetailId}
                        title={item.scentCardOptionsName}
                      >
                        <img
                          id={item.scentCardOptionsId}
                          ref={imageRef.current[index]}
                          src={item.storedPath}
                          alt={item.scentCardOptionsName}
                          key={Math.random()}
                        />
                        <p>{item.scentCardOptionsName}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </section>
            <section className="howto_wrapper">
              <ul className="howto_items">
                <li className="howto_item">
                  <img src="/resources/images/rubscent.png" alt="rubscent" />
                  <h6>
                    Rub
                    <br />
                    Scent
                  </h6>
                </li>
                <li className="howto_item">
                  <img src="/resources/images/smell.png" alt="smell" />
                  <h6>Smell</h6>
                </li>
                <li className="howto_item">
                  <img src="/resources/images/select_scent.png" alt="select_scent" />
                  <h6>
                    Select
                    <br />
                    Scent
                  </h6>
                </li>
              </ul>
            </section>
            <section className="steps_wrapper">
              <div>
                <ul className="steps">
                  <li className={selectedScentCard === 1 ? 'step active' : 'step'}>
                    <span>SCENT</span>
                    <span className="count">1</span>
                  </li>
                  <li className="step_arrow">
                    <img src="/resources/images/arrow.png" alt="arrow" />
                  </li>
                  <li className={selectedScentCard === 2 ? 'step active' : 'step'}>
                    <span>SCENT</span>
                    <span className="count">2</span>
                  </li>
                  <li className="step_arrow">
                    <img src="/resources/images/arrow.png" alt="arrow" />
                  </li>
                </ul>
                <ul className="steps">
                  <li className={selectedScentCard === 3 ? 'step active' : 'step'}>
                    <span>SCENT</span>
                    <span className="count">3</span>
                  </li>
                  <li className="step_arrow">
                    <img src="/resources/images/arrow.png" alt="arrow" />
                  </li>
                  <li className={selectedScentCard === 4 ? 'step active' : 'step'}>
                    <span>SCENT</span>
                    <span className="count">4</span>
                  </li>
                  <li className="step_arrow">
                    <img src="/resources/images/arrow.png" alt="arrow" />
                  </li>
                  <li className={selectedScentCard === 5 ? 'step active' : 'step'}>
                    <span>SCENT</span>
                    <span className="count">5</span>
                  </li>
                </ul>
              </div>
            </section>
          </div>
          <footer>
            <p>
              <b>{dataConstant.footerMessage}</b>
            </p>
          </footer>
        </div>
        {/* Smell Intensity popup */}
        <div className="col-12 col-md-6 col-lg-5 col-xl-4">
          <Modal
            onCancel={cancelSmellIntensityPopup}
            centered
            style={{
              top: 150,
            }}
            visible={smellIntensityModalVisible}
            wrapClassName="cm-smellIntensity-modal"
          >
            <div className="popup_head mb-4">
              <h3>Smell Intensity?</h3>
            </div>
            <div className="row">
              <div className="col-12 text-center">
                <img className="img-fluid smell_range" src="/resources/images/smell_range.png" alt="" />
              </div>
            </div>
            <Radio.Group
              onChange={onSelectSmellIntensity}
              value={smellIntensityValue}
              name="smell-intensity-values"
              defaultValue={1}
              className="smell_popup mt-5 w-100"
            >
              <div className="row">
                <div className="col-3 text-center">
                  <Radio id="soft" value={1}>
                    Mild
                  </Radio>
                </div>
                <div className="col-3 text-center">
                  <Radio id="medium" value={2}>
                    Medium
                  </Radio>
                </div>
                <div className="col-3 text-center">
                  <Radio id="strong" value={3}>
                    Strong
                  </Radio>
                </div>
                <div className="col-3 text-center">
                  <Radio id="intense" value={4}>
                    Intense
                  </Radio>
                </div>
              </div>
            </Radio.Group>
          </Modal>
        </div>
      </Spin>
    </React.Fragment>
  );
};
export default Scent;
