import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, Input, Switch, notification, Space, Spin } from 'antd';
import urlConstant from '../../../constants/urlConstant';
import commonService from '../../../services/commonService';
import dataConstant from '../../../constants/dataConstant';

function ManageInsuranceProvider(props) {
  let userRole;
  let insuranceProviderId = parseInt(props.match.params.Id);
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const messageTitle = 'Insurance Provider';

  useEffect(() => {
    let LoginUserDetails = localStorage.getItem('user');
    userRole = JSON.parse(LoginUserDetails).roleName;
    if (userRole != dataConstant.Role.Admin) {
      history.push('/admin/dashboard');
    }
  }, []);
  // get InsuranceProvider data by id IF InsuranceProvider id is not so it will set Oraganization state as active by default
  useEffect(() => {
    if (insuranceProviderId) {
      getInsuranceProviderById();
    } else {
      form.setFieldsValue({
        InsuranceProviderIsActive: true,
        DiscloseUserDetails: true,
      });
    }
  }, [insuranceProviderId]);

  // get InsuranceProvider data by id
  const getInsuranceProviderById = () => {
    commonService
      .httpGet(`${urlConstant.InsuranceProvider.GetInsuranceProviderById}?Id=${insuranceProviderId}`, true)
      .then((response) => {
        if (response && response.status === dataConstant.APIStatus.Success) {
          form.setFieldsValue({
            InsuranceProviderId: response.data.insuranceProviderId,
            InsuranceProviderName: response.data.insuranceProviderName,
            InsuranceProviderWebsite: response.data.insuranceProviderWebsite,
            InsuranceProviderAddress: response.data.insuranceProviderAddress,
            InsuranceProviderEmail: response.data.insuranceProviderEmail,
            InsuranceProviderPhone: response.data.insuranceProviderPhone,
            InsuranceProviderIsActive: response.data.insuranceProviderIsActive,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // save InsuranceProvider data with create or update condition
  const onFinish = (insuranceProviderDetail) => {
    setLoader(true);
    const requestURL =
      insuranceProviderDetail.InsuranceProviderId > 0
        ? urlConstant.InsuranceProvider.EditInsuranceProvider
        : urlConstant.InsuranceProvider.CreateInsuranceProvider;

    commonService.httpPost(requestURL, insuranceProviderDetail, true).then((response) => {
      if (response && response.status === dataConstant.APIStatus.Success) {
        setLoader(false);
        notification.success({
          message: messageTitle,
          description: response.message,
        });
        redirectToInsuranceProvider();
      } else {
        setLoader(false);
        notification.warning({
          message: messageTitle,
          description: response.message,
        });
      }
    });
  };

  // redirect to client page from cancel button
  const redirectToInsuranceProvider = () => {
    history.push({
      pathname: `/admin/insuranceProvider`,
    });
  };

  // loader component
  if (loader) {
    return (
      <Space size="middle" className="cmLoader">
        <Spin size="small"></Spin>
      </Space>
    );
  }
  return (
    <div className="manage-test-kit cm-add-organization-wrapper">
      <div className="page-header col-12 page_ttl">
        <strong>{insuranceProviderId ? 'Edit Insurance Provider' : 'Create Insurance Provider'}</strong>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Form className="row" form={form} name="userForm" autoComplete="off" onFinish={onFinish}>
            <Form.Item className="col-md-4" name="InsuranceProviderId" style={{ display: 'none' }}>
              <Input size="default" />
            </Form.Item>
            <Form.Item
              className="col-md-4"
              name="InsuranceProviderName"
              label="Insurance Provider Name"
              rules={[
                { required: true, message: dataConstant.manageInsuranceProvider.insuranceProviderNameRequired },
                { max: 100, message: dataConstant.manageInsuranceProvider.insuranceProviderNameCharacterLimit },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item className="col-md-4" name="InsuranceProviderEmail" label="Insurance Provider Email">
              <Input autoComplete="off" />
            </Form.Item>
            <Form.Item
              className="col-md-4"
              name="InsuranceProviderWebsite"
              label="Insurance Provider Website"
              rules={[{ pattern: dataConstant.Website.websiteFormat, message: dataConstant.Website.websiteValid }]}
            >
              <Input autoComplete="off" />
            </Form.Item>
            <Form.Item className="col-md-4" name="InsuranceProviderAddress" label="Insurance Provider Address">
              <Input autoComplete="off" />
            </Form.Item>
            <Form.Item
              className="col-md-4"
              name="InsuranceProviderPhone"
              label="Insurance Provider Phone#"
              rules={[{ min: 7, max: 17, message: dataConstant.manageUser.phoneLimit }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              className="col-md-4"
              name="InsuranceProviderIsActive"
              label="Insurance Provider Active"
              valuePropName="checked"
            >
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>

            <Form.Item className="col-12 text-right end" justify="end">
              <Button type="default" htmlType="button" className="mr-3" onClick={redirectToInsuranceProvider}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" className="mr-3">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ManageInsuranceProvider;
