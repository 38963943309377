import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet';
import { Form, Input, Button, Typography, Modal, Checkbox, Radio } from 'antd';
import Schema from 'async-validator';
import dataConstant from '../../constants/dataConstant';

const { Text } = Typography;

const Home = () => {
  Schema.warning = function () {};
  const [form] = Form.useForm();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCheckedTermsAndConditions, setIsCheckedTermsAndConditions] = useState(false);

  /** Removed localStorage and open Terms And Conditions popup  on page load */
  useEffect(() => {
    localStorage.removeItem('safePassData');
    localStorage.removeItem('testResultData');
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsModalVisible(true);
    }, 1000);
  }, []);

  /** On form submit store name in localStorage and redirect to addOrganization Page */
  const onFinish = (values) => {
    if (values.FirstName && values.LastName) {
      const safePassData = localStorage.getItem('safePassData');
      let userData = safePassData ? JSON.parse(safePassData) : {};
      userData.FirstName = values.FirstName;
      userData.LastName = values.LastName;
      // userData.isAddOrganizationChecked = values.isAddOrganizationChecked === 'true' ? true : false;
      userData.isAddOrganizationChecked = true;
      localStorage.setItem('safePassData', JSON.stringify(userData));
      history.push({
        pathname: '/addOrganizationPage',
      });
    }
  };

  /** store isAddOrganizationChecked in localStorage On AddOrganization checkbox change event */
  /** const onAddOrganizationChange = (e) => {
    const safePassData = localStorage.getItem('safePassData');
    let userData = safePassData ? JSON.parse(safePassData) : {};
    userData.isAddOrganizationChecked = e.target.checked;
    localStorage.setItem('safePassData', JSON.stringify(userData));
  }; */

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, height=device-height"
        />
      </Helmet>
      <section className="splash_wrapper">
        <div className="bg-dark">
          <img className="splash_logo" src="/resources/images/home_logo_white.png" alt="logo" />
          <p className="tagline">
            Your pass to <br />
            freedom
          </p>
          <Form
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
            name="control-hooks"
          >
            <div className="form-group name">
              <Form.Item
                id="FirstName"
                label="FIRST NAME:"
                name="FirstName"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: dataConstant.home.firstNameRequired,
                  },
                  {
                    max: 25,
                    message: dataConstant.home.firstNameCharacterLimit,
                  },
                ]}
              >
                <Input className="form-control" autoComplete="off" type="text" />
              </Form.Item>
            </div>
            <div className="form-group name">
              <Form.Item
                id="LastName"
                label="LAST NAME:"
                name="LastName"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: dataConstant.home.lastNameRequired,
                  },
                  {
                    max: 25,
                    message: dataConstant.home.lastNameCharacterLimit,
                  },
                ]}
              >
                <Input className="form-control" autoComplete="off" type="text" />
              </Form.Item>
            </div>
            <div className="form-group d-flex">
              {/* <Checkbox onChange={onAddOrganizationChange}></Checkbox> */}
              {/* <Form.Item
                className="text-sm no-text-transform"
                label="Was this test issued to you by a medical provider?"
                name="isAddOrganizationChecked"
                rules={[{ required: true, message: 'Please select an option.' }]}
              >
                <Radio.Group>
                  <Radio value="true"> Yes </Radio>
                  <Radio value="false"> No </Radio>
                </Radio.Group>
              </Form.Item> */}
            </div>
            <div className="action-btn">
              <Form.Item>
                <Button type="button" className="btn btn-primary" htmlType="submit">
                  <Text className="enterColor">
                    <b> Enter </b>
                  </Text>
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </section>
      <footer className="home_footer">
        <p>{dataConstant.home.footerHIPPAString}</p>
        <p>
          <b> {dataConstant.footerMessage} </b>
        </p>
      </footer>
      <Modal className="scrollable" closable={false} footer={null} centered visible={isModalVisible} onOk={handleOk}>
        <p>
          <b>{dataConstant.homePageTermsAndConditions.Title.Description}</b>
          &nbsp;
          {dataConstant.homePageTermsAndConditions.Message.Description}
        </p>
        <p>
          <b>{dataConstant.homePageTermsAndConditions.Title.Privacy}</b>
          &nbsp;
          {dataConstant.homePageTermsAndConditions.Message.Privacy}
        </p>
        <p>
          <b>{dataConstant.homePageTermsAndConditions.Title.Caution}</b>
          &nbsp;
          {dataConstant.homePageTermsAndConditions.Message.Caution}
        </p>
        <p>
          <b>{dataConstant.homePageTermsAndConditions.Title.TermsAndConditions}</b>
        </p>
        <p>
          <b>{dataConstant.homePageTermsAndConditions.Title.HippaAuthTitle}</b>
        </p>
        <p>{dataConstant.homePageTermsAndConditions.Message.HippaAuthMessage}</p>
        <p>
          <b>{dataConstant.homePageTermsAndConditions.Title.ThePatientUser}</b>
          &nbsp;
          {dataConstant.homePageTermsAndConditions.Message.ThePatientUser}
        </p>
        <p>
          <b>{dataConstant.homePageTermsAndConditions.Title.Authorization}</b>
          &nbsp;
          {dataConstant.homePageTermsAndConditions.Message.Authorization}
        </p>
        <p>
          <b>{dataConstant.homePageTermsAndConditions.Title.Disclosure}</b>
          &nbsp;
          {dataConstant.homePageTermsAndConditions.Message.Disclosure}
        </p>
        <p>
          <b>{dataConstant.homePageTermsAndConditions.Title.Purpose} </b>
          &nbsp;
          {dataConstant.homePageTermsAndConditions.Message.Purpose}
        </p>
        <p>
          <b>{dataConstant.homePageTermsAndConditions.Title.Termination}</b>
          &nbsp;
          {dataConstant.homePageTermsAndConditions.Message.Termination}
        </p>
        <p>
          <b>{dataConstant.homePageTermsAndConditions.Title.Acknowledgment} </b>
          &nbsp;
          {dataConstant.homePageTermsAndConditions.Message.Acknowledgment1}
        </p>
        <p> {dataConstant.homePageTermsAndConditions.Message.Acknowledgment4}</p>
        <p> {dataConstant.homePageTermsAndConditions.Message.Acknowledgment5}</p>
        <p> {dataConstant.homePageTermsAndConditions.Message.Acknowledgment2}</p>
        <p> {dataConstant.homePageTermsAndConditions.Message.Acknowledgment3}</p>
        <div className="cm-agree">
          <Checkbox onChange={(e) => setIsCheckedTermsAndConditions(e.target.checked ? true : false)}></Checkbox>
          <Button disabled={!isCheckedTermsAndConditions} className="termButton" type="primary" onClick={handleOk}>
            I AGREE TO THESE TERMS & CONDITIONS
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default Home;
