import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Select, Spin, DatePicker, Input } from 'antd';
import { httpGet, httpPost } from '../../services/commonService';
import urlConstant from '../../constants/urlConstant';
import dataConstant from '../../constants/dataConstant';

const { Option } = Select;

const AddInsuranceDetails = () => {
  const history = useHistory();
  const [userName, setUserName] = useState();
  const [isInsuranceProviderSelect, setIsInsuranceProviderSelect] = useState(false);
  const [insuranceProviderList, setInsuranceProviderList] = useState();
  const [isInsuranceRequired, setIsInsuranceRequired] = useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [insuranceId, setInsuranceId] = useState('');
  const [selectedInsuranceProvider, setSelectedInsuranceProvider] = useState(null);
  const [isOtherInsuranceProvider, setIsOtherInsuranceProvider] = useState(false);
  const [otherInsuranceProviderName, setOtherInsuranceProviderName] = useState('');
  const [dateOfBirthError, setDateOfBirthError] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    /** Check localstorage data (name) on page load */
    const safePassData = localStorage.getItem('safePassData');
    let userData = safePassData ? JSON.parse(safePassData) : null;
    if (userData && userData.FirstName && userData.LastName) {
      setUserName(`${userData.FirstName} ${userData.LastName}`);
    } else {
      history.push('/home');
    }
    /** Get localstorage data : isInsuranceRequired */
    if (userData && userData.isInsuranceRequired) {
      setIsInsuranceRequired(userData.isInsuranceRequired);
      getInsuranceProviderList();
    }
  }, []);

  /** Funxtion to call api for Organizatio dropdown */
  const getInsuranceProviderList = () => {
    setIsLoaderVisible(true);
    httpGet(urlConstant.InsuranceProvider.DropDownInsuranceProvider)
      .then((res) => {
        setInsuranceProviderList(res.data);
        setIsLoaderVisible(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoaderVisible(false);
      });
  };

  /** Go to next page ScanQRCode */
  const goToNext = () => {
    history.push({
      pathname: `/ScanQRCode`,
    });
  };

  /** After select InsuranceProvider, set selected InsuranceProvider in to localstorage */
  const onInsuranceProviderListChange = (key, value) => {
    console.log(key);
    let userData = JSON.parse(localStorage.getItem('safePassData'))
      ? JSON.parse(localStorage.getItem('safePassData'))
      : {};
    userData.insuranceProviderId = value.key;
    setSelectedInsuranceProvider(key);
    setInsuranceId('');
    if (value.children === 'Other') {
      setIsOtherInsuranceProvider(true);
      setOtherInsuranceProviderName('');
    } else {
      setIsOtherInsuranceProvider(false);
      setIsInsuranceProviderSelect(true);
      userData.insuranceProviderName = value.children;
    }
    localStorage.setItem('safePassData', JSON.stringify(userData));
  };

  const onDateOfBirthChange = (date) => {
    if (date && date.isAfter(moment())) {
      setDateOfBirthError('Invalid Date of Birth');
      setDateOfBirth(null);
    } else {
      setDateOfBirth(date);
      setDateOfBirthError('');
      let userData = JSON.parse(localStorage.getItem('safePassData'))
        ? JSON.parse(localStorage.getItem('safePassData'))
        : {};
      userData.DOB = date ? date.format(dataConstant.Report.ApiDateFormat) : null;
      if (userData.FirstName !== null && userData.LastName !== null && date !== null) {
        const requestObj = {
          FirstName: userData.FirstName.trim(),
          LastName: userData.LastName.trim(),
          DOB: date.format(dataConstant.Report.ApiDateFormat),
        };
        httpPost(urlConstant.TestResult.GetTestResultByDOB, requestObj, false)
          .then((response) => {
            if (response) {
              if (response.status === dataConstant.APIStatus.Success && response.data !== null) {
                userData.insuranceId = response.data.insuranceId;
                userData.insuranceProviderName = response.data.insuranceProviderName;
                setInsuranceId(response.data.insuranceId);
                setIsInsuranceProviderSelect(true);
                setSelectedInsuranceProvider(response.data.insuranceProviderName);
                localStorage.setItem('safePassData', JSON.stringify(userData));
              } else {
                userData.insuranceId = null;
                userData.insuranceProviderName = null;
                setInsuranceId(null);
                setIsInsuranceProviderSelect(false);
                setSelectedInsuranceProvider(null);
                localStorage.setItem('safePassData', JSON.stringify(userData));
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
      localStorage.setItem('safePassData', JSON.stringify(userData));
    }
  };

  const onOtherInsuranceProviderNameChange = (e) => {
    const otherName = e.target.value;
    setOtherInsuranceProviderName(otherName);
    let userData = JSON.parse(localStorage.getItem('safePassData'))
      ? JSON.parse(localStorage.getItem('safePassData'))
      : {};
    if (otherName !== null && otherName !== '' && otherName !== undefined) {
      setIsInsuranceProviderSelect(true);
    } else {
      setIsInsuranceProviderSelect(false);
    }
    userData.insuranceProviderName = otherName;
    localStorage.setItem('safePassData', JSON.stringify(userData));
  };

  const onInsuranceIdChange = (e) => {
    const id = e.target.value;
    setInsuranceId(id);

    let userData = JSON.parse(localStorage.getItem('safePassData'))
      ? JSON.parse(localStorage.getItem('safePassData'))
      : {};
    userData.insuranceId = id;
    localStorage.setItem('safePassData', JSON.stringify(userData));
  };

  return (
    <Spin spinning={isLoaderVisible} tip="Loading...">
      <div className="add_organization_page">
        <header className="d-flex align-items-center justify-content-between">
          <Link className="logo" to="/home">
            <img src="/resources/images/logo-white.png" alt="logo" />
          </Link>
        </header>
        <div className="add_organization_wrapper" style={{ backgroundColor: '#db3833' }}>
          <div className="profile_wrapper">
            <h1 className="profile_ttl">Your Profile:</h1>
            <div className="text-center profile_itm">
              <img className="user_icn" src="/resources/images/user.png" alt="user" />
              <h3 className="user_name">{userName}</h3>
            </div>
          </div>
          <div className="select_organization_wrapper">
            <h2>Date of Birth</h2>
            <DatePicker
              value={dateOfBirth ? moment(dateOfBirth, dataConstant.Report.ReportDateFormat.dateFormat) : null}
              onChange={onDateOfBirthChange}
              style={{ width: '100%' }}
              placeholder="Select Date of Birth"
              format={dataConstant.Report.ReportDateFormat.dateFormat}
              disabledDate={(current) => current && current.isAfter(moment().endOf('day'))}
            />
            {dateOfBirthError && <div style={{ color: 'red' }}>{dateOfBirthError}</div>}
          </div>
          <div className="select_organization_wrapper">
            <h2>Insurance Provider </h2>
            <div>
              <Select
                showSearch={{ caseSensitive: false }}
                onSelect={(key, value) => {
                  onInsuranceProviderListChange(key, value);
                }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                filterSort={(optionA, optionB) =>
                  optionA.children === 'Other'
                    ? 1
                    : optionB.children === 'Other'
                    ? -1
                    : optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
                value={selectedInsuranceProvider}
              >
                {insuranceProviderList &&
                  insuranceProviderList.map((item) => (
                    <Option key={item.id} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </div>
          </div>
          {isOtherInsuranceProvider && (
            <div className="select_organization_wrapper">
              <h2>Other Insurance Provider Name</h2>
              <Input
                value={otherInsuranceProviderName}
                onChange={onOtherInsuranceProviderNameChange}
                placeholder="Enter Other Insurance Provider Name"
                style={{ width: '100%' }}
              />
            </div>
          )}
          <div className="select_organization_wrapper">
            <h2>Insurance ID</h2>
            <Input
              value={insuranceId}
              onChange={onInsuranceIdChange}
              placeholder="Enter Insurance ID"
              style={{ width: '100%' }}
            />
          </div>
          <div className="select_organization_wrapper">
            <button
              disabled={
                isInsuranceRequired && (!isInsuranceProviderSelect || dateOfBirth === null || insuranceId === '')
              }
              className="nxt-btn btn"
              onClick={() => goToNext()}
            >
              Next
            </button>
          </div>
        </div>
        <footer>
          <p>
            <b> {dataConstant.footerMessage} </b>
          </p>
        </footer>
      </div>
    </Spin>
  );
};

export default AddInsuranceDetails;
