import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, Input, Switch, Radio, notification, Divider, Space, Spin, Checkbox, Modal } from 'antd';
import urlConstant from '../../../constants/urlConstant';
import commonService from '../../../services/commonService';
import dataConstant from '../../../constants/dataConstant';
import messageConstant from '../../../constants/messageConstant';

function ManageOrganization(props) {
  let organizationId = parseInt(props.match.params.Id);
  let userRole;
  const [isUseOrganizationEmail, setIsUseOrganizationEmail] = useState(false);
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const messageTitle = 'Organization';

  useEffect(() => {
    let LoginUserDetails = localStorage.getItem('user');
    userRole = JSON.parse(LoginUserDetails).roleName;
    if (userRole != dataConstant.Role.Admin) {
      history.push('/admin/dashboard');
    }
  }, []);
  // get organization data by id IF organization id is not so it will set Oraganization state as active by default
  useEffect(() => {
    if (organizationId) {
      getOrganizationById();
    } else {
      form.setFieldsValue({
        OrganizationIsActive: true,
        DiscloseUserDetails: true,
      });
    }
  }, [organizationId]);

  // get organization data by id
  const getOrganizationById = () => {
    commonService
      .httpGet(`${urlConstant.Organization.GetOrganizationById}?Id=${organizationId}`, true)
      .then((response) => {
        if (response && response.status === dataConstant.APIStatus.Success) {
          form.setFieldsValue({
            OrganizationId: response.data.organizationId,
            OrganizationName: response.data.organizationName,
            OrganizationCode: response.data.organizationCode,
            OrganizationWebsite: response.data.organizationWebsite,
            OrganizationAddress: response.data.organizationAddress,
            OrganizationEmail: response.data.organizationEmail,
            OrganizationPhone: response.data.organizationPhone,
            OrganizationIsActive: response.data.organizationIsActive,
            UserFirstName: response.data.userFirstName,
            UserLastName: response.data.userLastName,
            UserEmail: response.data.userEmail,
            UserPhone: response.data.userPhone,
            UserIsActive: response.data.userIsActive,
            DiscloseUserDetails: response.data.discloseUserDetails,
            ClientType: response.data.clientType,
            InsuranceIsRequired: response.data.insuranceIsRequired,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Check Active Condition
  const confirmIsActive = (value) => {
    value === false
      ? Modal.confirm({
          title: messageConstant.IsActive,
          content: (
            <>
              {messageConstant.IsActiveConfirmation} <br />
              {messageConstant.SureMessage}
            </>
          ),
          okText: 'Yes',
          cancelText: 'No',
          onOk: () => {
            form.setFieldsValue({
              OrganizationIsActive: false,
            });
          },
          onCancel: () => {
            form.setFieldsValue({
              OrganizationIsActive: true,
            });
          },
        })
      : null;
  };

  // save organization data with create or update condition
  const onFinish = (organizationDetail) => {
    setLoader(true);
    const requestURL =
      organizationDetail.OrganizationId > 0
        ? urlConstant.Organization.EditOrganization
        : urlConstant.Organization.CreateOrganization;

    commonService.httpPost(requestURL, organizationDetail, true).then((response) => {
      if (response && response.status === dataConstant.APIStatus.Success) {
        setLoader(false);
        notification.success({
          message: messageTitle,
          description: response.message,
        });
        redirectToClient();
      } else {
        setLoader(false);
        notification.warning({
          message: messageTitle,
          description: response.message,
        });
      }
    });
  };

  // redirect to client page from cancel button
  const redirectToClient = () => {
    history.push({
      pathname: `/admin/client`,
    });
  };

  // Copy organization email to user email if checkbox is checked
  const copyOrgEmailAsUserEmail = () => {
    if (isUseOrganizationEmail) {
      form.setFieldsValue({
        UserEmail: form.getFieldValue('OrganizationEmail'),
      });
    }
  };

  // checkbox change event for copy organization email to user email
  const IsUseOrganizationEmail = (e) => {
    e.target.checked
      ? (setIsUseOrganizationEmail(true),
        form.setFieldsValue({
          UserEmail: form.getFieldValue('OrganizationEmail'),
        }))
      : setIsUseOrganizationEmail(false);
  };

  // loader component
  if (loader) {
    return (
      <Space size="middle" className="cmLoader">
        <Spin size="small"></Spin>
      </Space>
    );
  }
  return (
    <div className="manage-test-kit cm-add-organization-wrapper">
      <div className="page-header col-12 page_ttl">
        <strong>{organizationId ? 'Edit Organization / Medical provider' : 'Create Organization / provider'}</strong>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Form className="row" form={form} name="userForm" autoComplete="off" onFinish={onFinish}>
            <Form.Item
              className="col-md-12"
              label="Client Type"
              name="ClientType"
              rules={[{ required: true, message: 'Please select client type!' }]}
            >
              <Radio.Group>
                <Radio value="Medical provider"> Medical provider </Radio>
                <Radio value="Organization"> Organization </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item className="col-md-4" name="OrganizationId" style={{ display: 'none' }}>
              <Input size="default" />
            </Form.Item>
            <Form.Item
              className="col-md-4"
              name="OrganizationName"
              label="Organization Name"
              rules={[
                { required: true, message: dataConstant.manageOrganization.organizationNameRequired },
                { max: 100, message: dataConstant.manageOrganization.organizationNameCharacterLimit },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className="col-md-4"
              name="OrganizationCode"
              label="Organization Code"
              rules={[
                { required: true, message: dataConstant.manageOrganization.organizationCodeRequired },
                { max: 200, message: dataConstant.manageOrganization.organizationCodeCharacterLimit },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className="col-md-4"
              name="OrganizationEmail"
              label="Organization Email"
              rules={[
                { required: true, message: dataConstant.manageOrganization.organizationEmailRequired },
                { type: 'email', message: dataConstant.Email.emailValid },
              ]}
            >
              <Input autoComplete="off" onChange={() => copyOrgEmailAsUserEmail()} />
            </Form.Item>
            <Form.Item
              className="col-md-4"
              name="OrganizationWebsite"
              label="Organization Website"
              rules={[{ pattern: dataConstant.Website.websiteFormat, message: dataConstant.Website.websiteValid }]}
            >
              <Input autoComplete="off" />
            </Form.Item>

            <Form.Item className="col-md-4" name="OrganizationAddress" label="Organization Address">
              <Input autoComplete="off" />
            </Form.Item>
            <Form.Item
              className="col-md-4"
              name="OrganizationPhone"
              label="Organization Phone#"
              rules={[{ min: 7, max: 17, message: dataConstant.manageUser.phoneLimit }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              className="col-md-4"
              name="DiscloseUserDetails"
              label="Disclose Username"
              valuePropName="checked"
            >
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
            <Form.Item
              className="col-md-4"
              name="OrganizationIsActive"
              label="Organization Active"
              valuePropName="checked"
            >
              <Switch onChange={confirmIsActive} checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
            <Form.Item
              className="col-md-4"
              name="InsuranceIsRequired"
              label="Insurance Details Required:"
              valuePropName="checked"
            >
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>

            <Divider className="m-0 mb-4" />
            <div className="page-header col-12 page_ttl">
              <strong>
                {organizationId
                  ? 'Edit Organization User / Medical provider'
                  : 'Create Organization User /Medical provider'}
              </strong>
            </div>

            <Form.Item className="col-md-4" name="Id" style={{ display: 'none' }}>
              <Input size="default" />
            </Form.Item>
            <Form.Item
              className="col-md-4"
              name="UserFirstName"
              label="First Name"
              rules={[
                { required: true, message: dataConstant.manageUser.firstNameRequired },
                { max: 25, message: dataConstant.manageUser.firstNameCharacterLimit },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className="col-md-4"
              name="UserLastName"
              label="Last Name"
              rules={[
                { required: true, message: dataConstant.manageUser.lastNameRequired },
                { max: 25, message: dataConstant.manageUser.lastNameCharacterLimit },
              ]}
            >
              <Input autoComplete="off" />
            </Form.Item>
            <Form.Item
              className="col-md-4"
              name="UserPhone"
              label="Phone#"
              rules={[{ min: 7, max: 17, message: dataConstant.manageUser.phoneLimit }]}
            >
              <Input />
            </Form.Item>
            <div className="col-md-4 px-0 email">
              <Form.Item
                className="mb-0"
                name="UserEmail"
                label="Email"
                rules={[
                  { required: true, message: dataConstant.Email.emailRequired },
                  { type: 'email', message: dataConstant.Email.emailValid },
                ]}
              >
                <Input disabled={isUseOrganizationEmail} autoComplete="off" />
              </Form.Item>
              <div className="form-group d-flex organization_email">
                <Checkbox checked={isUseOrganizationEmail} onChange={IsUseOrganizationEmail}></Checkbox>
                <p>Use Organization Email.</p>
              </div>
              <p className="remark text-muted">(This Email will be used for Testkit data)</p>
            </div>
            {!organizationId && (
              <>
                <Form.Item
                  className="col-md-4"
                  name="Password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: dataConstant.manageUser.passwordRequired,
                    },
                    {
                      pattern: dataConstant.passwordPattern,
                      message: messageConstant.InvalidPasswordPattern,
                    },
                  ]}
                >
                  <Input.Password autoComplete="off" />
                </Form.Item>

                <Form.Item
                  className="col-md-4"
                  name="ConfirmPassword"
                  label="Confirm Password"
                  dependencies={['Password']}
                  rules={[
                    {
                      required: true,
                      message: dataConstant.manageUser.confirmPasswordReqired,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('Password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(dataConstant.manageUser.confirmPasswordDoesNotMatch));
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </>
            )}
            <Divider className="m-0 mb-4" />
            <Form.Item className="col-12 text-right end" justify="end">
              <Button type="default" htmlType="button" className="mr-3" onClick={redirectToClient}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" className="mr-3">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ManageOrganization;
