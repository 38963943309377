import React, { useEffect, useState, useMemo } from 'react';
import { Tabs, Button, Form, Input, notification, Tooltip, Modal, Row, Image } from 'antd';
const { TabPane } = Tabs;
import { debounce } from 'lodash';
import { useHistory } from 'react-router';
import { EditOutlined, DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons';
import TableComponent from '../../../TableComponent';
import commonService from '../../../services/commonService';
import urlConstant from '../../../constants/urlConstant';
import messageConstant from '../../../constants/messageConstant';
import dataConstant from '../../../constants/dataConstant';
import defaultQRCodeImage from './../../../images/scan-qr-code-default.png';
let userRole;

const ManageTestKit = (props) => {
  const child = React.createRef();
  const history = useHistory();

  const [searchForm] = Form.useForm();
  const [scentCardData, setScentCardData] = useState({
    scentCardTotalCount: 0,
    scentCardData: [],
  });
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const debounceFilterData = useMemo(() => debounce(applyFilter, 1000), []);
  const [activeKey, setActiveKey] = useState('1');
  const [form] = Form.useForm();
  const [testKitId, setTestKitId] = useState(props.match.params.Id);
  const [scentCardId, setScentCardId] = useState(props.match.params.ScentCardId);
  const [testKitDetalis, setTestKitDetalis] = useState({
    Kitname: '',
    QrCode: null,
    QrCodeUrl: '',
  });
  const [tableRequestParam, setTableRequestParam] = useState({
    pageNumber: dataConstant.tableRequest.pageNumber,
    rowsPerPage: JSON.parse(localStorage.getItem('RowsPerPage'))
      ? JSON.parse(localStorage.getItem('RowsPerPage'))
      : dataConstant.tableRequest.rowsPerPage,
    filter: {
      Name: '',
      CorrectAnswer: '',
    },
    testKitId,
    sortColumns: dataConstant.tableRequest.sortColumns,
  });

  useEffect(() => {
    let LoginUserDetails = localStorage.getItem('user');
    userRole = JSON.parse(LoginUserDetails).roleName;
    if (userRole != dataConstant.Role.Admin) {
      history.push('/admin/dashboard');
    }
  }, []);

  useEffect(() => {
    if (searchText !== '') {
      debounceFilterData(searchText);
    }
  }, [searchText]);

  function applyFilter(SearchValue) {
    if (SearchValue !== '') {
      const filter = {};
      filter.Name = SearchValue;
      filter.CorrectAnswer = SearchValue;

      const searchModelData = tableRequestParam;
      searchModelData.filter = filter;
      searchModelData.pageNumber = dataConstant.tableRequest.pageNumber;
      (searchModelData.rowsPerPage = JSON.parse(localStorage.getItem('RowsPerPage'))
        ? JSON.parse(localStorage.getItem('RowsPerPage'))
        : dataConstant.tableRequest.rowsPerPage),
        getScentCardDetail(tableRequestParam);
    }
  }

  const gridRefresh = () => {
    setSearchText('');
    searchForm.resetFields();
    let refreshTableData = tableRequestParam;
    refreshTableData.pageNumber = dataConstant.tableRequest.pageNumber;
    (refreshTableData.rowsPerPage = JSON.parse(localStorage.getItem('RowsPerPage'))
      ? JSON.parse(localStorage.getItem('RowsPerPage'))
      : dataConstant.tableRequest.rowsPerPage),
      (refreshTableData.filter = {
        Name: '',
        CorrectAnswer: '',
      });
    setTableRequestParam(refreshTableData);
    refreshTableData.sortColumns = dataConstant.tableRequest.sortColumns;
    getScentCardDetail(refreshTableData);
  };

  useEffect(() => {
    if (testKitId) {
      getTestKitById();
    }
    if (scentCardId) {
      handleTabChange('2');
    }
    if (props.location.state == '2') {
      setActiveKey('2');
      getScentCardDetail();
    }
  }, [testKitId]);

  const getTestKitById = () => {
    setIsLoaderVisible(true);
    commonService.httpGet(`${urlConstant.TestKit.GetTestKitById}?Id=${testKitId}`, true).then((response) => {
      if (response.status === dataConstant.APIStatus.Success) {
        form.setFieldsValue({
          Id: response.data.id,
          Name: response.data.name,
          IsActive: response.data.isActive,
          QRCode: response.data.qrCode,
          QRCodeURL: response.data.qrCodeURL,
        });
        setTestKitDetalis({
          Kitname: response.data.name,
          QrCode: response.data.qrCode,
          QrCodeUrl: response.data.qrCodeURL,
        });
        setIsLoaderVisible(false);
      } else if (response.status === dataConstant.APIStatus.Failure) {
        notification.warning({
          message: 'Test Kit',
          description: response.message,
        });
        history.push({
          pathname: `/admin/testKit`,
        });
        return;
      }
    });
  };

  // call on form submit(both edit and add case)
  const onFinish = (formData) => {
    const requestURL = formData.Id > 0 ? urlConstant.TestKit.UpdateTestKit : urlConstant.TestKit.CreateTestkit;
    commonService.httpPost(requestURL, formData, true).then((response) => {
      if (response) {
        if (response.status === dataConstant.APIStatus.Success) {
          notification.success({
            message: `Test Kit`,
            description: response.message,
          });
          history.replace({
            pathname: `/admin/manageTestkit/${response.data.testKitId}`,
            state: name,
          });
          getScentCardDetail();
          setTestKitId(response.data.testKitId);
          setActiveKey('2');
        } else {
          notification.warning({
            message: `Test Kit`,
            description: response.message,
          });
        }
      }
    });
  };

  const getScentCardDetail = (requestObject) => {
    setIsLoaderVisible(true);
    const modelData = tableRequestParam;

    if (requestObject) {
      modelData.testKitId = testKitId;
      modelData.pageNumber = requestObject.pageNumber;
      modelData.rowsPerPage = requestObject.rowsPerPage;
      modelData.sortColumns = requestObject.sortColumns;
      modelData.filter = requestObject.filter ? requestObject.filter : tableRequestParam.filter;
    }
    commonService.httpPost(urlConstant.ScentCard.GetSentCardByTestkitId, modelData, true).then((response) => {
      if (response && response.data) {
        setScentCardData({
          scentCardData: response.data.list,
          scentCardTotalCount: response.data.length,
        });
        setIsLoaderVisible(false);
      }
    });
  };

  const editScentCard = (id) => {
    history.push({
      pathname: `/admin/manageScentCard/${testKitId}/${id}`,
    });
  };

  const columns = [
    {
      title: 'Action',
      dataIndex: 'action',
      fixed: true,
      align: 'center',
      width: 100,
      render: (text, record) => (
        <span>
          <Tooltip placement="bottom" title="Edit">
            <Button icon={<EditOutlined />} className="mr-1" size="small" onClick={() => editScentCard(record.id)} />
          </Tooltip>
        </span>
      ),
    },
    {
      title: 'Scent Card Page',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      width: 200,
      dataType: 'StringContains',
    },
    {
      title: 'Scent Card Order',
      dataIndex: 'sortOrder',
      key: 'sortOrder',
      sorter: true,
      width: 200,
      dataType: 'StringContains',
    },
    {
      title: 'Correct Answer',
      dataIndex: 'title',
      key: 'title',
      // sorter: true,
      width: 200,
      dataType: 'StringContains',
    },
    {
      title: 'Delete',
      dataIndex: 'Delete',
      align: 'center',
      width: 100,
      render: (text, record) => (
        <span>
          <Tooltip placement="bottom" title="Delete">
            <Button icon={<DeleteOutlined />} size="small" onClick={() => confirmDelete(record.id)} />
          </Tooltip>
        </span>
      ),
    },
  ];

  // delete dialogue box
  const confirmDelete = (id) => {
    Modal.confirm({
      title: messageConstant.DeleteConfirmation,
      content: messageConstant.DeleteConfirm,
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        singleDeleteScentCard(id);
      },
    });
  };

  // Delete method
  const singleDeleteScentCard = (Ids) => {
    commonService.httpGet(urlConstant.ScentCard.DeleteScentCard + '?Id' + `=${Ids}`, true).then((response) => {
      if (response.status === dataConstant.APIStatus.Success) {
        notification.success({
          message: 'Scent Card',
          description: response.message,
        });
        getScentCardDetail();
      } else if (response.status === dataConstant.APIStatus.Failure) {
        notification.warning({
          message: 'Scent Card',
          description: response.error,
        });
      }
    });
  };

  const redirectToAddScentCard = () => {
    history.push({
      pathname: `/admin/manageScentCard/${testKitId}/0`,
      state: { CurrentTestKitName: testKitDetalis.Kitname },
    });
  };

  const Refresh = (current) => {
    const initRequestParams = {
      pageNumber: 1,
      rowsPerPage: JSON.parse(localStorage.getItem('RowsPerPage'))
        ? JSON.parse(localStorage.getItem('RowsPerPage'))
        : dataConstant.tableRequest.rowsPerPage,
      filter: {
        Name: '',
        CorrectAnswer: '',
      },
      sortColumns: dataConstant.tableRequest.sortColumns,
    };
    setSearchText('');
    searchForm.resetFields();
    getScentCardDetail(initRequestParams);
  };

  const handleTabChange = (activeKey) => {
    setActiveKey(activeKey);
    if (activeKey == '2') {
      getScentCardDetail();
    }
  };

  const redirectToTestKit = () => {
    history.push({
      pathname: `/admin/testKit`,
    });
  };

  const goPrevious = () => {
    history.push({
      pathname: `/admin/testKit`,
    });
  };

  return (
    <div className="manage-test-kit">
      <div className="page-header">
        <strong>{testKitId || scentCardId ? `Edit Test Kit: ${testKitDetalis.Kitname}` : 'Create Test Kit'}</strong>
      </div>
      <Tabs onChange={(e) => handleTabChange(e)} tabPosition="left" activeKey={activeKey}>
        <TabPane tab="Test Kit Info" key="1">
          <Form
            form={form}
            name="basic"
            initialValues={{
              remember: true,
            }}
            layout="inline"
            onFinish={onFinish}
            autoComplete="off"
            size="large"
          >
            <Form.Item name="Id" style={{ display: 'none' }}>
              <Input size="default" />
            </Form.Item>
            <Form.Item
              label="Name"
              id="Name"
              name="Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter name',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="default" htmlType="button" className="mr-3" onClick={redirectToTestKit}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                {testKitId ? 'Save' : 'Create'}
              </Button>
            </Form.Item>
          </Form>
          {testKitDetalis && testKitDetalis.QrCodeUrl ? (
            <>
              <div className="test-kit-qr-code d-flex flex-column">
                <label className="qr-code-label">QR Code</label>
                <div className="test-qr-code">
                  <Image
                    src={testKitDetalis.QrCode || defaultQRCodeImage}
                    width={200}
                    height={200}
                    id="QRCode"
                    name="QRCode"
                  />
                </div>
                <Button
                  className="download-qr-code"
                  type="primary"
                  htmlType="button"
                  download="qr.png"
                  href={testKitDetalis.QrCode || ''}
                  disabled={!testKitId}
                >
                  Download QR Code
                </Button>
              </div>{' '}
            </>
          ) : null}
        </TabPane>

        <TabPane tab="Scent Option Info" key="2" disabled={!testKitId}>
          <div className="d-flex align-item-center justify-content-between">
            <h1>Scent Card</h1>
            <Button className="btn btn-outline-secondary" type="primary" htmlType="submit" onClick={goPrevious}>
              Back
            </Button>
          </div>
          <Form form={searchForm}>
            <Row justify="end">
              <Form.Item id="data" label="Search" name="searchtext" className="col-6">
                <Input placeholder="Search" onChange={(e) => setSearchText(e.target.value)} />
              </Form.Item>
              <Button icon={<CloseCircleOutlined />} onClick={() => gridRefresh()} htmlType="submit" type="primary" />
            </Row>
          </Form>
          <TableComponent
            ref={child}
            rowKey="id"
            columns={columns}
            dataSource={scentCardData.scentCardData}
            getGridData={getScentCardDetail}
            totalCount={scentCardData.scentCardTotalCount}
            scroll={{ x: 'max-content' }}
            loading={isLoaderVisible}
            refresh={(current) => Refresh(current)}
            isShowAddButton
            addCallbackMethod={redirectToAddScentCard}
            pageNumber={tableRequestParam.pageNumber}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ManageTestKit;
