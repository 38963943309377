import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Select, Spin } from 'antd';
import { httpGet } from '../../services/commonService';
import urlConstant from '../../constants/urlConstant';
import dataConstant from '../../constants/dataConstant';
const { Option } = Select;

const AddOrganizationPage = () => {
  const history = useHistory();
  const [userName, setUserName] = useState();
  const [isOrganizationSelect, setIsOrganizationSelect] = useState(false);
  const [organizationList, setOrganizationList] = useState();
  const [isAddOrganizationChecked, setIsAddOrganizationChecked] = useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [isInsuranceRequired, setIsInsuranceRequired] = useState(false);
  const [organization, setOrganization] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    /** Check localstorage data (name) on page load */
    const safePassData = localStorage.getItem('safePassData');
    let userData = safePassData ? JSON.parse(safePassData) : null;
    if (userData && userData.FirstName && userData.LastName) {
      setUserName(`${userData.FirstName} ${userData.LastName}`);
    } else {
      history.push('/home');
    }
    /** Get localstorage data : isAddOrganizationChecked */
    if (userData && userData.isAddOrganizationChecked) {
      setIsAddOrganizationChecked(userData.isAddOrganizationChecked);
      getOrganizationList();
    }
  }, []);

  /** Function to call api for Organization dropdown */
  const getOrganizationList = () => {
    setIsLoaderVisible(true);
    httpGet(urlConstant.Organization.DropDownOrganization)
      .then((res) => {
        setOrganizationList(res.data);
        setIsLoaderVisible(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoaderVisible(false);
      });
  };

  /** Go to next page ScanQRCode */
  const goToNext = () => {
    if (isInsuranceRequired) {
      history.push({
        pathname: `/AddInsuranceDetails`,
      });
    } else {
      history.push({
        pathname: `/ScanQRCode`,
      });
    }
  };

  /** After select Organization, set selected Organization in to localstorage */
  const onOrganizationListChange = (value) => {
    let userData = JSON.parse(localStorage.getItem('safePassData'))
      ? JSON.parse(localStorage.getItem('safePassData'))
      : {};
    const selectedOrganization = organizationList.find((org) => org.id === value);
    setOrganization(selectedOrganization.code);
    userData.organizationId = selectedOrganization.id;
    userData.isInsuranceRequired = selectedOrganization.insuranceIsRequired;
    setIsOrganizationSelect(true);
    setIsInsuranceRequired(selectedOrganization.insuranceIsRequired);
    localStorage.setItem('safePassData', JSON.stringify(userData));
  };

  return (
    <Spin spinning={isLoaderVisible} tip="Loading...">
      <div className="add_organization_page">
        <header className="d-flex align-items-center justify-content-between">
          <Link className="logo" to="/home">
            <img src="/resources/images/logo-white.png" alt="logo" />
          </Link>
        </header>
        <div className="add_organization_wrapper" style={{ backgroundColor: '#db3833' }}>
          <div className="profile_wrapper">
            <h1 className="profile_ttl">Your Profile:</h1>
            <div className="text-center profile_itm">
              <img className="user_icn" src="/resources/images/user.png" alt="user" />
              <h3 className="user_name">{userName}</h3>
            </div>
          </div>
          {isAddOrganizationChecked === true ? (
            <div className="select_organization_wrapper">
              <h2>Select Organization / Healthcare Provider </h2>
              <p>Choose the medical provider or organization that provided you with this test</p>
              <div>
                <Select
                  showSearch={{ caseSensitive: false }}
                  onSelect={onOrganizationListChange}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                  value={organization}
                >
                  {organizationList &&
                    organizationList.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.code}
                      </Option>
                    ))}
                </Select>
              </div>
              <p>Note: Test results are forwarded to the provider selected.</p>
            </div>
          ) : null}

          <div className="select_organization_wrapper">
            <button
              disabled={isAddOrganizationChecked && !isOrganizationSelect}
              className="nxt-btn btn"
              onClick={goToNext}
            >
              Next
            </button>
          </div>
        </div>
        <footer>
          <p>
            <b> {dataConstant.footerMessage} </b>
          </p>
        </footer>
      </div>
    </Spin>
  );
};

export default AddOrganizationPage;
