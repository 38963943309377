import React, { useEffect, useState, useMemo } from 'react';
import { Tooltip, Button, Modal, notification, Form, Col, Row, Input, Image } from 'antd';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import { EditOutlined, DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons';
import TableComponent from '../../../TableComponent';
import dataConstant from '../../../constants/dataConstant';
import urlConstant from '../../../constants/urlConstant';
import commonService from '../../../services/commonService';
import messageConstant from '../../../constants/messageConstant';
let userRole;

const ScentCardData = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [scentOptionData, setScentOptionData] = useState({
    scentOptionTotalCount: 0,
    scentOptionData: [],
  });
  const [searchText, setSearchText] = useState('');
  const debounceFilterData = useMemo(() => debounce(applyFilter, 1000), []);
  const [tableRequestParam, setTableRequestParam] = useState({
    pageNumber: dataConstant.tableRequest.pageNumber,
    rowsPerPage: JSON.parse(localStorage.getItem('RowsPerPage'))
      ? JSON.parse(localStorage.getItem('RowsPerPage'))
      : dataConstant.tableRequest.rowsPerPage,
    filter: {
      Name: '',
    },
    sortColumns: dataConstant.tableRequest.sortColumns,
  });
  const child = React.createRef();

  useEffect(() => {
    let LoginUserDetails = localStorage.getItem('user');
    userRole = JSON.parse(LoginUserDetails).roleName;
    if (userRole != dataConstant.Role.Admin) {
      history.push('/admin/dashboard');
    }
    getScentOptionData(tableRequestParam);
  }, []);

  const getScentOptionData = (requestObject) => {
    setIsLoaderVisible(true);
    const modelData = tableRequestParam;

    if (requestObject) {
      modelData.pageNumber = requestObject.pageNumber;
      modelData.rowsPerPage = requestObject.rowsPerPage;
      modelData.sortColumns = requestObject.sortColumns;
      modelData.filter = requestObject.filter ? requestObject.filter : tableRequestParam.filter;
    }
    commonService
      .httpPost(urlConstant.ScentOption.GetScentCardOptionDetailList, modelData, true, true)
      .then((response) => {
        if (response && response.data) {
          setScentOptionData({
            scentOptionData: response.data.list,
            scentOptionTotalCount: response.data.totalCount,
          });
          setIsLoaderVisible(false);
        }
      });
  };

  useEffect(() => {
    if (searchText !== '') {
      debounceFilterData(searchText);
    } else {
      gridRefresh();
    }
  }, [searchText]);

  const gridRefresh = () => {
    form.resetFields();
    let refreshTableData = tableRequestParam;
    refreshTableData.pageNumber = dataConstant.tableRequest.pageNumber;
    refreshTableData.rowsPerPage = JSON.parse(localStorage.getItem('RowsPerPage'))
      ? JSON.parse(localStorage.getItem('RowsPerPage'))
      : dataConstant.tableRequest.rowsPerPage;
    refreshTableData.filter = {
      Name: '',
    };
    refreshTableData.sortColumns = dataConstant.tableRequest.sortColumns;
    setTableRequestParam(refreshTableData);
    getScentOptionData(refreshTableData);
  };

  function applyFilter(SearchValue) {
    if (SearchValue !== '') {
      const filter = {};
      filter.title = SearchValue;

      const searchModelData = tableRequestParam;
      searchModelData.filter = filter;
      searchModelData.pageNumber = dataConstant.tableRequest.pageNumber;
      searchModelData.rowsPerPage = JSON.parse(localStorage.getItem('RowsPerPage'))
        ? JSON.parse(localStorage.getItem('RowsPerPage'))
        : dataConstant.tableRequest.rowsPerPage;
      getScentOptionData(tableRequestParam);
    }
  }

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: 'Edit',
      dataIndex: 'Edit',
      fixed: true,
      align: 'center',
      width: 30,
      render: (text, record) => (
        <span>
          <Tooltip placement="bottom" title="Edit">
            <Button
              icon={<EditOutlined />}
              className="mr-1"
              size="small"
              onClick={() => editScentOption(record.id, record.name)}
            />
          </Tooltip>
        </span>
      ),
    },
    {
      title: 'Image',
      dataIndex: 'storedPath',
      key: 'storedPath',
      sorter: true,
      width: 10,
      align: 'center',
      render: (text, record) => (
        <span>
          <Image
            src={record.storedPath}
            width={50}
            height={50}
            id="StoredPath"
            name="StoredPath"
            alt="StoredPath"
            preview={record.storedPath ? true : false}
          />
        </span>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      width: 400,
      dataType: 'StringContains',
    },
    {
      title: 'Delete',
      dataIndex: 'Delete',
      align: 'center',
      width: 30,
      render: (text, record) => (
        <span>
          <Tooltip placement="bottom" title="Delete">
            <Button icon={<DeleteOutlined />} size="small" onClick={() => handleDelete(record.id)} />
          </Tooltip>
        </span>
      ),
    },
  ];

  const Refresh = (current) => {
    let refreshTableData = tableRequestParam;
    (refreshTableData.pageNumber = dataConstant.tableRequest.pageNumber),
      (refreshTableData.rowsPerPage = JSON.parse(localStorage.getItem('RowsPerPage'))
        ? JSON.parse(localStorage.getItem('RowsPerPage'))
        : dataConstant.tableRequest.rowsPerPage),
      (refreshTableData.filter = {
        Name: '',
      }),
      (refreshTableData.sortColumns = dataConstant.tableRequest.sortColumns),
      setSearchText('');
    form.resetFields();
    getScentOptionData(tableRequestParam);
  };

  const redirectToAddScentOption = () => {
    history.push({
      pathname: `/admin/createScentCardData`,
    });
  };

  const editScentOption = (id, name) => {
    history.push({
      pathname: `/admin/editScentCardData/${id}`,
      state: name,
    });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: messageConstant.DeleteConfirmation,
      content: messageConstant.DeleteConfirm,
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        deleteSingleScentOption(id.toString());
      },
    });
  };

  const deleteSingleScentOption = (Ids) => {
    commonService.httpGet(`${urlConstant.ScentOption.DeleteScentCardOptionDetail}?Id=${Ids}`, true).then((response) => {
      if (response.status === dataConstant.APIStatus.Success) {
        notification.success({
          message: 'Scent option',
          description: response.message,
        });
        getScentOptionData();
      } else if (response.status === dataConstant.APIStatus.Failure) {
        notification.warning({
          message: 'Scent option',
          description: response.error,
        });
      }
    });
  };

  return (
    <div>
      <div className="page-header">
        <h1>Scent options</h1>
        <Form form={form}>
          <Row justify="end">
            <Form.Item id="data" label="Search" name="searchtext" className="col-12 col-md-6">
              <div className="d-flex">
                <Input placeholder="Search" onChange={(e) => setSearchText(e.target.value)} />
                <Button
                  icon={<CloseCircleOutlined />}
                  onClick={() => setSearchText('')}
                  htmlType="submit"
                  type="primary"
                />
              </div>
            </Form.Item>
          </Row>
        </Form>
      </div>

      <Modal
        title="Test Answer Detail"
        visible={isModalVisible}
        okText="Close"
        onOk={closeModal}
        onCancel={closeModal}
        width="1000px"
        bodyStyle={{ height: 'auto' }}
        maskClosable={false}
      ></Modal>
      <TableComponent
        ref={child}
        rowKey="id"
        columns={columns}
        dataSource={scentOptionData.scentOptionData}
        getGridData={getScentOptionData}
        totalCount={scentOptionData.scentOptionTotalCount}
        scroll={{ x: 'max-content' }}
        loading={isLoaderVisible}
        refresh={(current) => Refresh(current)}
        isShowAddButton
        addCallbackMethod={redirectToAddScentOption}
        pageNumber={tableRequestParam.pageNumber}
      />
    </div>
  );
};

export default ScentCardData;
