const urlConstant = {
  User: {
    Authenticate: `/api/User/Authenticate`,
    ChangePassword: `/api/User/ChangePassword`,
    GetUserList: `/api/User/GetUserList`,
    GetUserById: `/api/User/GetUserById`,
    CreateUser: `/api/User/CreateUser`,
    UpdateUser: `/api/User/UpdateUser`,
    DeleteUser: `/api/User/DeleteUser`,
    ResetPassword: `/api/User/ResetPassword`,
  },
  Role: {
    getRoles: `/api/Role/GetRoles`,
  },
  PkPass: {
    generatePkPass: `/api/PkPassConfig/GeneratePKSafePass`,
  },
  TestResult: {
    SaveTestResult: `/api/TestResult/SaveTestResult`,
    SendResultInEmail: `/api/TestResult/SendResultInEmail`,
    GetTestResultCounts: `/api/TestResult/GetTestResultCounts`,
    GetTestResultList: `/api/TestResult/GetTestResultList`,
    GetTestAnswerResultDetailList: `/api/TestResult/GetTestAnswerResultDetailList`,
    SaveQuestionnaireResult: `api/TestResult/SaveQuestionnaireResult`,
    SendQuestionDetailsInEmail: `/api/TestResult/SendQuestionnaireResultInEmail`,
    GetQuestionnaireResultList: `/api/TestResult/GetQuestionnaireAnswerList`,
    GetTestStatus: `/api/TestResult/FindTestResultId?id=`,
    SaveUserMedicalInformation: `/api/TestResult/SaveUserMedicalInformation`,
    ExportCsv: `/api/TestResult/ExportCsv`,
    GetTestResultByDOB: `/api/TestResult/GetTestResultByDOB`,
  },
  ScentCard: {
    GetSentCardDetailsById: `api/SentCard/GetSentCardDetails`,
    GetSentCardByTestkitId: `/api/SentCard/GetScentCardByTestKitId`,
    GetSentCardDetails: `api/SentCard/GetSentCardDetails?testKitName=`,
    DeleteScentCard: `/api/SentCard/DeleteScentCard`,
    GetScentCardDetailById: `/api/SentCard/GetScentCardDetailById?Id=`,
    CreateSentCardDetails: `/api/SentCard/CreateSentCardDetails`,
    UpdateSentCardDetails: `/api/SentCard/UpdateSentCardDetails`,
  },

  ScentOption: {
    CreateScentCardOptionDetail: `/api/ScentCardOption/CreateScentCardOptionDetail`,
    GetScentCardOptionDetailList: `/api/ScentCardOption/GetScentCardOptionDetailList`,
    GetScentCardOptionDetailById: `/api/ScentCardOption/GetScentCardOptionDetailById?Id=`,
    DeleteScentCardOptionDetail: `/api/ScentCardOption/DeleteScentCardOptionDetail`,
    UpdateScentCardOption: `/api/ScentCardOption/UpdateScentCardOptionDetail`,
  },

  TestKit: {
    GetTestKit: `/api/TestKit/GetTestKit`,
    DeleteTestKit: `/api/TestKit/DeleteTestKit`,
    ExportCSV: `/api/TestKit/ExportCSV`,
    CreateTestkit: `/api/TestKit/SaveTestKit`,
    GetTestKitById: `/api/TestKit/GetTestKitById`,
    UpdateTestKit: `/api/TestKit/UpdateTestKit`,
    GetTestNameList: `/api/TestKit/GetTestNameList`,
  },
  ScentCardOptionDetail: {
    DropdownForScentCardOption: `/api/ScentCardOption/DropDownForScentCardOption`,
  },
  Organization: {
    GetOrganization: `/api/Organization/GetOrganizationList`,
    DropDownOrganization: `/api/Organization/DropDownOrganization`,
    CreateOrganization: `/api/Organization/CreateOrganization`,
    EditOrganization: `/api/Organization/EditOrganization`,
    GetOrganizationById: `/api/Organization/GetOrganizationById`,
    DeleteOrganizationById: `/api/Organization/DeleteOrganizationById`,
    PassFailDataByDate: `api/Organization/PassFailDataByDate`,
    GetSearchValues: `/api/Organization/GetLocationData`,
    GetDataForGoogleMap: `/api/Organization/GetDataForGoogleMap`,
  },
  homePCROption: {
    targetURL: `https://www.mysafepass.us/freehomepcr`,
  },
  questionaire: {
    targetURL: `https://www.mysafepass.us/questionaire`,
  },
  helpURL: {
    help: `https://www.mysafepass.us/help`,
    contactus: `https://www.mysafepass.us/contactus`,
  },
  GoogleMarkerIcon: `http://maps.google.com/mapfiles/ms/icons/`,
  InsuranceProvider: {
    GetInsuranceProvider: `/api/InsuranceProvider/GetInsuranceProviderList`,
    DropDownInsuranceProvider: `/api/InsuranceProvider/DropDownInsuranceProvider`,
    CreateInsuranceProvider: `/api/InsuranceProvider/CreateInsuranceProvider`,
    EditInsuranceProvider: `/api/InsuranceProvider/EditInsuranceProvider`,
    GetInsuranceProviderById: `/api/InsuranceProvider/GetInsuranceProviderById`,
    DeleteInsuranceProviderById: `/api/InsuranceProvider/DeleteInsuranceProviderById`,
  },
};

export { urlConstant as default };
