/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
const google = window.google;
import { Radio, Form, DatePicker, Button, Divider, notification, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import _ from 'lodash';
import '../../../custom.css';
import messageConstant from '../../../constants/messageConstant';
import dataConstant from '../../../constants/dataConstant';
import commonService from '../../../services/commonService';
import urlConstant from '../../../constants/urlConstant';
const { Option, OptGroup } = Select;
const url = urlConstant.Organization.GetDataForGoogleMap;
let organizationId;

let userRole;
const dateFormat = dataConstant.Report.ReportDateFormat.dateFormat;

function initMap() {
  const [performanceForm] = Form.useForm();
  const [filterOption, setFilterOption] = useState('A');
  const [organizationList, setOrganizationList] = useState([]);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [getfilterDate, setGetfilterDate] = useState({
    startDate: moment().startOf('year').format(dataConstant.Report.ApiDateFormat), // get current year start date
    endDate: moment().endOf('year').format(dataConstant.Report.ApiDateFormat), // get current year end date
  });

  const [searchBy, setSearchBy] = useState(dataConstant.Report.SearchByMonth);

  // eslint-disable-next-line no-undef
  var map;

  useEffect(() => {
    // getChartDetails();
    let LoginUserDetails = localStorage.getItem('user');
    userRole = JSON.parse(LoginUserDetails).roleName;
    organizationId = JSON.parse(LoginUserDetails).organizationId;

    performanceForm.setFieldsValue({
      searchBy: dataConstant.Report.SearchByMonth,
    });
    // set radio button value to search by month
    getOrganizationList();
    // set organization dropdown value to Show All
    performanceForm.setFieldsValue({
      filterBy: 'A',
    });
  }, []);

  useEffect(() => {
    if (filterOption) {
      getChartDetails(getfilterDate.startDate, getfilterDate.endDate);
    }
  }, [filterOption]);

  const getOrganizationList = () => {
    commonService.httpGet(urlConstant.Organization.DropDownOrganization, true).then((response) => {
      if (response && response.data) {
        setOrganizationList(response.data);
      }
    });
  };

  const getChartDetails = (fromDateFormat, toDateFormat) => {
    setGetfilterDate({
      startDate: fromDateFormat ? fromDateFormat : moment().startOf('year').format(dataConstant.Report.ApiDateFormat),
      endDate: toDateFormat ? toDateFormat : moment().endOf('year').format(dataConstant.Report.ApiDateFormat),
    });
    setIsLoaderVisible(true);
    const data = {
      DataSetType:
        userRole === dataConstant.Role.ClientAdmin
          ? dataConstant.DataSetType.GetAllChartDataWithOrganizationId
          : userRole === dataConstant.Role.Admin && filterOption === dataConstant.DataSetType.GetAllChartData
          ? dataConstant.DataSetType.GetAllChartData
          : userRole === dataConstant.Role.Admin && filterOption === dataConstant.DataSetType.GetAllChartDataWithNullId
          ? dataConstant.DataSetType.GetAllChartDataWithNullId
          : dataConstant.DataSetType.GetAllChartDataWithOrganizationId,
      FromDate: fromDateFormat ? fromDateFormat : moment().startOf('year').format(dataConstant.Report.ApiDateFormat),
      ToDate: toDateFormat ? toDateFormat : moment().endOf('year').format(dataConstant.Report.ApiDateFormat),
      OrganizationId:
        userRole === dataConstant.Role.ClientAdmin
          ? parseInt(organizationId)
          : userRole === dataConstant.Role.Admin &&
            (filterOption != dataConstant.DataSetType.GetAllChartData ||
              filterOption != dataConstant.DataSetType.GetAllChartDataWithNullId)
          ? parseInt(filterOption)
          : null,
    };
    commonService
      .httpPost(url, data, true)
      .then((response) => {
        if (response.status === dataConstant.APIStatus.Success) {
          if (response.data) {
            let markers = [];
            var mapOptions = {
              center: new google.maps.LatLng(47.116386, -101.299591),
              zoom: 3,
              mapTypeId: 'roadmap',
              streetViewControl: false,
              featureType: 'poi.business',
            };
            map = new google.maps.Map(document.getElementById('map'), mapOptions);
            _.forEach(response.data, function (location) {
              let icon =
                location.resultStatus == dataConstant.resultStatut.Pass
                  ? dataConstant.resultStatut.Green
                  : location.resultStatus == dataConstant.resultStatut.Fail
                  ? dataConstant.resultStatut.Red
                  : dataConstant.resultStatut.Blue;
              var latLng = new google.maps.LatLng(location.latitude, location.longitude);

              let marker = new google.maps.Marker({
                position: latLng,
                animation: google.maps.Animation.DROP,
                icon: `./resources/images/${icon}.png`,
                map: map,
                title: location.resultStatus,
              });
              markers.push(marker);
            });
            let markerCluster = new MarkerClusterer({ map, markers });
            setIsLoaderVisible(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoaderVisible(false);
      });
  };

  const onSubmit = (values) => {
    if (values.fromDate >= values.toDate) {
      notification.warning({
        message: messageConstant.Report.ToDate,
        description: messageConstant.Report.DateValidation,
      });
    }
    var fromDateFormat = values.fromDate
      ? values.fromDate.format(dataConstant.Report.ReportDateFormat.dateFormat)
      : null;
    var toDateFormat = values.toDate ? values.toDate.format(dataConstant.Report.ReportDateFormat.dateFormat) : null;

    getChartDetails(fromDateFormat, toDateFormat);
  };

  const onChange = (e) => {
    setSearchBy(e.target.value);
    if (e.target.value === dataConstant.Report.SearchByMonth) {
      getChartDetails();
    }
  };

  const handleChange = (value) => {
    setFilterOption(value);
  };

  window.initMap = initMap;

  return (
    <>
      <div className="page-header">
        <h1>Location Map</h1>
      </div>
      <div className="search_organization_wrapper cm-MapData">
        <Form className="row" form={performanceForm} onFinish={onSubmit}>
          {userRole === dataConstant.Role.Admin && (
            <Form.Item
              required={false}
              label="Organization"
              name="filterBy"
              id="filterBy"
              validateTrigger="onChange"
              className="col-12"
              rules={[
                {
                  required: true,
                  message: messageConstant.Report.FilterValidation,
                },
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children === undefined ? false : option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children === undefined
                    ? false
                    : optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
                style={{
                  width: 200,
                }}
                onChange={handleChange}
              >
                <OptGroup label="Admin option">
                  <Option value="A">All Data</Option>
                  <Option value="N">Non-Oragnization Data</Option>
                </OptGroup>
                <OptGroup label="Organization">
                  {organizationList.length > 0 &&
                    organizationList.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                </OptGroup>
              </Select>
            </Form.Item>
          )}
          <Form.Item valuePropName="checked" className="col-md-4" id="searchBy" name="searchBy">
            <Radio.Group onChange={onChange} value={searchBy}>
              <Radio value="searchByMonth">Screening Performance of Current Year</Radio>
              <Radio value="searchByDate">Screening Performance by Date</Radio>
            </Radio.Group>
          </Form.Item>

          {searchBy == 'searchByDate' ? (
            <div className="col-md-8 date_picker_wrapper">
              <Form.Item
                id="fromDate"
                name="fromDate"
                label="From"
                rules={[{ required: true, message: messageConstant.Report.FromDateValidation }]}
              >
                <DatePicker
                  format={dateFormat}
                  name="fromDate"
                  disabledDate={(current) => {
                    return current && current > moment().endOf('day');
                  }}
                />
              </Form.Item>
              <Form.Item
                id="toDate"
                name="toDate"
                label="To"
                rules={[{ required: true, message: messageConstant.Report.ToDateValidation }]}
              >
                <DatePicker
                  format={dateFormat}
                  disabledDate={(current) => {
                    return current && current < performanceForm.getFieldValue('fromDate');
                  }}
                />
              </Form.Item>
              {searchBy == 'searchByDate' && (
                <Button className="btn btn-outline-secondary mx-auto" type="primary" htmlType="submit">
                  Show
                </Button>
              )}
            </div>
          ) : null}
        </Form>
      </div>
      <Divider />
      <Spin spinning={isLoaderVisible} tip="Loading...">
        <div style={{ margin: 'auto' }} id="map"></div>
      </Spin>
    </>
  );
}

export default initMap;
