/* eslint-disable */
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from 'react-router';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import {MenuOutlined} from '@ant-design/icons';
import { Modal, Form, Input, notification } from "antd";
import Schema from "async-validator";
import "./NavMenu.css";
import messageConstant from "../../constants/messageConstant";
import commonService, { logout } from "../../services/commonService";
import urlConstant from "../../constants/urlConstant";
import dataConstant from "../../constants/dataConstant";

const NavMenu = () => {
	Schema.warning = function () { };
	const [form] = Form.useForm();
	const history = useHistory();
	const [isOpen, setIsOpen] = React.useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const user = JSON.parse(localStorage.getItem("user") || "{}");
	const organizationName = user.organizationName || "";

	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};
	//assigning location variable
	const location = useLocation();

	const openModal = () => {
		setIsModalVisible(true);
	};

	const closeModal = () => {
		setIsModalVisible(false);
		form.resetFields();
	};

	const locationData = () => {
		history.push({
			pathname: '/admin/Reports/locationData',
		});
	}

	const dataLocation = () => {
		history.push({
			pathname: '/admin/Reports/dataLocation',
		});
	}

	const passFailData = () => {
		history.push({
			pathname: '/admin/Reports/passFailData',
		});
	}

	const userPage = () => {
		history.push({
			pathname: '/admin/user',
		});
	}

	const clientPage = () => {
		history.push({
			pathname: '/admin/client',
		});
	}

	const scentOptionPage = () => {
		history.push({
			pathname: '/admin/scentCardData',
		});
	}

	const insuranceProviderPage = () => {
		history.push({
			pathname: '/admin/insuranceProvider',
		});
	}

	const savePassword = () => {
		form.validateFields().then(async (resetPasswordData) => {
			if (resetPasswordData) {
				commonService
					.httpPost(urlConstant.User.ChangePassword, resetPasswordData, true)
					.then((response) => {
						if (response.status === "Success") {
							notification.success({
								message: "Password",
								description: response.message,
							});
							closeModal();
						} else if (response.status === "Failure") {
							notification.warning({
								message: "Password",
								description: response.message,
							});
							// closeModal()
						}
					}).catch((error) => {
						if (error.response) {
							notification.warning({
								message: "Password",
								description: error,
							});
						}
					});
				form.resetFields();
			}
		});
	};

	//destructuring pathname from location
	return (
		<>
			<Modal
				visible={isModalVisible}
				title={"Change Password"}
				centered
				okText="Save"
				width={500}
				onOk={savePassword}
				onCancel={closeModal}
			>
				<Form
					form={form}
					autoComplete="off"
					className="cm-country-form"
				>
					<Form.Item id="id" name="id" style={{ display: "none" }}>
						<Input />
					</Form.Item>
					<Form.Item
						id="oldPassword"
						name="oldPassword"
						label="Current Password"
						rules={[{ required: true, message: "Password is required" }]}

					>
						<Input.Password autoComplete='new-password' />
					</Form.Item>
					<Form.Item
						id="newPassword"
						name="newPassword"
						label="New Password"
						rules={[
							{ required: true, message: "New Password is required" },
							{
								pattern: /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
								message: "Must contain one uppercase, atleast 8 characters, one lowercase, one special character"
							}
						]}
					>
						<Input.Password />
					</Form.Item>
					<Form.Item
						name="confirmPassword"
						label="Confirm Password"
						dependencies={["newPassword"]}
						rules={[
							{
								required: true,
								message: `${messageConstant.Required} Confirm Password`,
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue("newPassword") === value) {
										return Promise.resolve();
									}

									return Promise.reject(
										new Error(messageConstant.ConfirmPassword)
									);
								},
							}),
						]}
					>
						<Input.Password />
					</Form.Item>
				</Form>
			</Modal>
			<header>
				<Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3 admin-menu">
					<Container>
						<NavbarBrand tag={Link} to="/admin/dashboard" ><img src="/resources/images/logo.png" className="logo-admin" alt="logo" /></NavbarBrand>
						{user.roleName == dataConstant.Role.ClientAdmin ? <h3>Welcome {organizationName}</h3> : null}
						<NavbarToggler onClick={toggleMenu} className="mr-2" >
							<MenuOutlined translate={undefined} />
						</NavbarToggler>
						<Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={isOpen} navbar>
							<ul className="navbar-nav flex-grow">
								{(user.roleName == dataConstant.Role.ClientAdmin || user.roleName == dataConstant.Role.Admin) &&
									<NavItem className={location.pathname === "/admin/dashboard" ? "active" : ""}>
										<NavLink tag={Link} className="text-dark" to="/admin/dashboard">Dashboard</NavLink>
									</NavItem>
								}
								{user.roleName == dataConstant.Role.Admin &&
									<NavItem className={(["/admin/testkit", "admin/manageTestkit"].find(x => location.pathname.includes(x))) ? "active" : ""}>
										<NavLink tag={Link} className="text-dark" to="/admin/testkit">Test Kits</NavLink>
									</NavItem>
								}
								{(user.roleName == dataConstant.Role.ClientAdmin || user.roleName == dataConstant.Role.Admin) &&
									<NavItem className={location.pathname === "/admin/testresult" ? "active" : ""}>
										<NavLink tag={Link} className="text-dark" to="/admin/testresult">Test Results</NavLink>
									</NavItem>
								}
								{(user.roleName == dataConstant.Role.ClientAdmin || user.roleName == dataConstant.Role.Admin) &&
									<UncontrolledDropdown nav inNavbar className={(["/admin/Reports/locationData", "/admin/Reports/dataLocation","/admin/Reports/passFailData"].find(x => location.pathname.includes(x)) ? "active pb-2 pb-sm-0" : "pb-2 pb-sm-0")}>
										<DropdownToggle nav caret>
											Reports
										</DropdownToggle>
										<DropdownMenu right>
											<DropdownItem onClick={locationData} className={location.pathname === "/admin/Reports/locationData" ? "active" : ""}>
												Location Map
											</DropdownItem>
											<DropdownItem divider />
											<DropdownItem onClick={dataLocation} className={location.pathname === "/admin/Reports/dataLocation" ? "active" : ""}>
												<span>Data Location</span>
											</DropdownItem>
											<DropdownItem divider />
											<DropdownItem onClick={passFailData} className={location.pathname === "/admin/Reports/passFailData" ? "active" : ""}>
												<span>Pass Fail Data</span>
											</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown>
								}
								{(user.roleName == dataConstant.Role.Admin) &&
									<UncontrolledDropdown nav inNavbar className={(["/admin/user", "/admin/client","/admin/scentCardData"].find(x => location.pathname.includes(x)) ? "active pb-2 pb-sm-0" : "pb-2 pb-sm-0	")}>
										<DropdownToggle nav caret>
											Masters
										</DropdownToggle>
										<DropdownMenu right>
											<DropdownItem onClick={userPage} className={location.pathname === "/admin/user" ? "active" : ""}>
												Users
											</DropdownItem>
											<DropdownItem divider />
											<DropdownItem onClick={clientPage} className={location.pathname === "/admin/client" ? "active" : ""}>
												<span >Client</span>
											</DropdownItem>
											<DropdownItem onClick={insuranceProviderPage} className={location.pathname === "/admin/insuranceProvider" ? "active" : ""}>
												<span >Insurance Provider</span>
											</DropdownItem>
											<DropdownItem divider />
											<DropdownItem onClick={scentOptionPage} className={location.pathname === "/admin/scentCardData" ? "active" : ""}>
												<span >Scent Options</span>
											</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown>
								}
								<UncontrolledDropdown nav inNavbar className="pb-2 pb-sm-0">
									<DropdownToggle nav caret>
										{user.firstName} {user.lastName}
									</DropdownToggle>
									<DropdownMenu right>
										<DropdownItem onClick={openModal}>
											Change Password
										</DropdownItem>
										<DropdownItem divider />
										<DropdownItem onClick={logout}>
											<span className="text-danger">Logout</span>
										</DropdownItem>
									</DropdownMenu>
								</UncontrolledDropdown>
							</ul>
						</Collapse>
					</Container>
				</Navbar>
			</header>
		</>
	);
};

export default NavMenu;
